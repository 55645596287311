import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { EScene } from '../enums/e-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogMessageComponent } from '../error-dialog-message/error-dialog-message.component';

export class LoadGameScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'LoadGamePNGs';
    PREFIX = 'LoadGame_';
    dialog: MatDialog;

    LOADLABEL : PIXI.Text = null;
    FILE: any = null;
    NEXT: any = null;

    constructor(dialog: MatDialog){
        super();
        this.dialog = dialog;
    }

    DisplayError(text: string): void{
        let errorMessage = this.dialog.open(ErrorDialogMessageComponent);
        errorMessage.componentInstance.message = text;
    }

    UpdateScene(): void {}
    
    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const toplabel = this.LoadSprite('toplabel',608,62);
        const notebookbackground = this.LoadSprite('notebookbackground',970,630);
        notebookbackground.anchor.set(0.5,0.5);   
        const info = this.LoadText("- Click here to select your saved game -", 40, 2);
        info.interactive = true;
        info.buttonMode = true;
        info.anchor.set(0.5,0.5);   
        info.style = this.GetTextStyle();
        this.LOADLABEL = this.LoadText("No Save File selected.", 40, -105);
        this.LOADLABEL.anchor.set(0.5,0.5);
        this.LOADLABEL.style = this.GetLoadTextStyle();

        const reference = this;

        
        // next Button
        this.NEXT = new PButton(
            this.LoadTexture('next'),
            this.LoadTexture('next_hover'),
            this.LoadTexture('next_click'),
            1665, 825
        );
        this.NEXT.on('click', (event) => {
            this.LoadGame();
        });
        this.NEXT.visible = false;
        this.NEXT.interactive = false;

        var myLoad = document.getElementById('myLoad') as HTMLInputElement;
        if (myLoad.files[0]){
            this.LOADLABEL.text = "Your selected file:\r" + myLoad.files[0].name;
            this.FILE = myLoad.files[0];
            this.NEXT.visible = true;
            this.NEXT.interactive = true;
        }

        info.on('click', (event) => {
            var myLoad = document.getElementById('myLoad') as HTMLInputElement;
            myLoad.click();
            myLoad.onchange = function (event) {
                reference.FILE = (event.target as any).files[0];
                if (reference.FILE){
                    reference.LOADLABEL.text = "Your selected file:\r" + reference.FILE.name;
                    reference.NEXT.visible = true;
                    reference.NEXT.interactive = true;
                } else {
                    reference.LOADLABEL.text = "No Save File selected.";
                    reference.NEXT.visible = false;
                    reference.NEXT.interactive = false;
                }
                HSceneManager.INSTANCE.animate();
            };
        });

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            35, 825
        );
        back.on('click', (event) => {
            this.GoBack();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(back);
        container.addChild(this.NEXT);
        container.addChild(toplabel);
        notebookbackground.addChild(info);
        notebookbackground.addChild(this.LOADLABEL);
        container.addChild(notebookbackground);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'LoadGame_Background.png');
        this.AddResource('back', 'LoadGame_BackButton.png');
        this.AddResource('back_click', 'LoadGame_BackButton_Click.png');
        this.AddResource('back_hover', 'LoadGame_BackButton_Hover.png');
        this.AddResource('next', 'LoadGame_NextButton.png');
        this.AddResource('next_click', 'LoadGame_NextButton_Click.png');
        this.AddResource('next_hover', 'LoadGame_NextButton_Hover.png');
        this.AddResource('toplabel', 'LoadGame_PickSaveGame.png');
        this.AddResource('notebookbackground', 'LoadGame_Notizbuch.png');
    }

    GoBack(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.MAINMENU);
    }

    LoadGame(): void {
        const reference = this;
        HSceneManager.INSTANCE.changeSceneById(EScene.LOADINGSCREEN);
        if (this.FILE) {
            var reader = new FileReader();
            reader.readAsText(this.FILE, "UTF-8");
            reader.onload = function (evt) {
                try {
                    if (PGameState.load(JSON.parse(evt.target.result as string))){
                        console.log('Load Successful');
                        HSceneManager.INSTANCE.changeSceneById(EScene.COMPUTERSCREEN);
                    } else {
                        reference.DisplayError("This savestate is not compatible with the current version of 'Follow the Money'. File could not get loaded.");
                        HSceneManager.INSTANCE.changeSceneById(EScene.LOADGAMESCREEN);
                    }
                } catch (error) {
                    reference.DisplayError("This file is not a savestate for 'Follow the Money'. File could not get loaded.");
                    HSceneManager.INSTANCE.changeSceneById(EScene.LOADGAMESCREEN);
                }
            }
            reader.onerror = function (evt) {
                reference.DisplayError("Your selected file could not get accessed. It is either corrupted or not readable.");
                HSceneManager.INSTANCE.changeSceneById(EScene.LOADGAMESCREEN);
            }
        }
    }

    GetTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 597,
            fontSize: 26,
            align: 'center'
        });
    }

    GetLoadTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 400,
            breakWords: true,
            fontSize: 26,
            fontWeight: 'bold',
            align: 'center'
        });
    }
}
