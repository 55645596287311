import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';

export class ComputerEmailScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'Email';
    PREFIX = 'ComputerEmailScreen_';
    MAILS = [
        {
            name: 'Mr. Developer',
            title: 'Welcome to Follow the Money!',
            content: 'Welcome to Follow the Money!\nIn this game scenario, you try to uncover a huge money laundering scheme in ALKONIA!\n\nIn your file folder, you will be able to find all of your available evidences and request new ones.\nYou can start different investigations based on your selected role, which you can switch at the top right corner of your screen.\n\nThe whiteboard serves as a general overview of your current progress, while e-mails and phone conversations might grant you\nadditionalevidences.\n\nBe aware though. Requesting evidences costs steps, which are this games resource.\n\nIf you have any feedback for the development of this game, feel free to use the\nFeedback Button on the computer to send us an e-mail.\n\nGood Luck and Enjoy the game!',
            email: 'm.hoelzenbein@quantumfrog.de',
        }
    ];

    LABELS = {
        title: null,
        name: null,
        email: null,
        content: null
    }

    UpdateScene(): void {}
    
    BuildScene(): PIXI.Container {
        if (PGameState.level == 1){
            this.MAILS = [
                {
                    name: 'Mr. Developer',
                    title: 'Welcome to Follow the Money!',
                    content: 'Welcome to Follow the Money!\nIn this game scenario, you try to uncover a weird sales record for MyFrogs.com, which did not result in any additional revenue.\n\nIn your file folder, you will be able to find all of your available evidences and request new ones.\nYou can start different investigations based on your selected role, which you can switch at the top right corner of your screen.\n\nThe whiteboard serves as a general overview of your current progress, while e-mails and phone conversations might grant you\nadditional evidences.\n\nBe aware though. Requesting evidences costs steps, which are this games resource.\n\nIf you have any feedback for the development of this game, feel free to use the\nFeedback Button on the computer to send us an e-mail.\n\nGood Luck and Enjoy the game!',
                    email: 'm.hoelzenbein@quantumfrog.de',
                }
            ];
        }
        else if (PGameState.level == 2){
            this.MAILS = [];

            for (var i=0; i<PGameState.seizedAssets.length; i++){
                var PTargetModel
                this.MAILS.push({
                    name: 'Court of Alkonia',
                    title: 'CASE:: ASSET-AL00' + PGameState.seizedAssets[i],
                    content: 'Hello,\nYour request to seize the following asset has been granted:\n\nASSET-AL00' + PGameState.seizedAssets[i] + '\n' + PGameState.allTargets[PGameState.seizedAssets[i]].name + '\n\nWe feel confident about your case and will proceed with the seizure in the following days.\n\nRegards,\nCourt of Alkonia',
                    email: 't.dallmeier@court.al',
                });
            }

            this.MAILS.push(
                {
                    name: 'Mr. Developer',
                    title: 'Welcome to Follow the Money!',
                    content: 'Welcome to Follow the Money!\nIn this game scenario, you try to uncover a weird revenue record for GasEmpire, which did not result in any additional revenue.\n\nIn your file folder, you will be able to find all of your available evidences and request new ones.\nYou can start different investigations based on your selected role, which you can switch at the top right corner of your screen.\n\nThe whiteboard serves as a general overview of your current progress, while e-mails and phone conversations might grant you\nadditional evidences.\n\nBe aware though. Requesting evidences costs steps, which are this games resource.\n\nIf you have any feedback for the development of this game, feel free to use the\nFeedback Button on the computer to send us an e-mail.\n\nGood Luck and Enjoy the game!',
                    email: 'm.hoelzenbein@quantumfrog.de',
                }
            );
        }

        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',431,121);
        
        // Add Elements to Canvas
        container.addChild(background);

        for (let i=0; i<this.MAILS.length; i++){
            var mail = new PButton(
                this.LoadTexture('mail'),
                this.LoadTexture('mail_hover'),
                this.LoadTexture('mail_click'),
                492, 258 + (i*62)
            );

            mail.on('click', (event) => {
                this.LABELS.title.text = this.MAILS[i].title;
                this.LABELS.name.text = this.MAILS[i].name;
                this.LABELS.email.text = this.MAILS[i].email;
                this.LABELS.content.text = this.MAILS[i].content;
                HSceneManager.INSTANCE.animate();
            });
    
            var name = this.LoadText(this.MAILS[i].name, 542, 272 + (i*62));
            name.style = this.GetMailHeaderStyle();
    
            var title = this.LoadText(this.MAILS[i].title, 542, 292 + (i*62));
            title.style = this.GetMailTitleStyle();

            container.addChild(mail);
            container.addChild(name);
            container.addChild(title);
        }

        this.LABELS.title = this.LoadText('', 860, 233);
        this.LABELS.title.style = this.GetMailTitleStyle();
        container.addChild(this.LABELS.title);

        this.LABELS.name = this.LoadText('', 860, 270);
        this.LABELS.name.style = this.GetMailHeaderStyle();
        container.addChild(this.LABELS.name);

        this.LABELS.email = this.LoadText('', 860, 290);
        this.LABELS.email.style = this.GetMailTitleStyle();
        container.addChild(this.LABELS.email);

        this.LABELS.content = this.LoadText('', 860, 360);
        this.LABELS.content.style = this.GetMailContentStyle();
        container.addChild(this.LABELS.content);

        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'Email_Vorlage.png');
        this.AddResource('mail', 'Eingang.png');
        this.AddResource('mail_click', 'Eingang_Click.png');
        this.AddResource('mail_hover', 'Eingang_Hover.png');
    }

    GetMailHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 18,
            fontWeight: 'bold'
        });
    }

    GetMailTitleStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 14,
            fontWeight: 'normal'
        });
    }

    GetMailContentStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 14,
            fontWeight: 'normal',
            wordWrapWidth: 4,
        });
    }
}
