import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';

export class PDragContainer extends PIXI.Container {
    data: any;
    dragging: boolean;
    offset: any;
    oldPosition: any;

    constructor() {
        super();
        this.interactive = true;
        this.buttonMode = true;

        // setup events
        this
            // events for drag start
            .on('mousedown', this.onDragStart)
            .on('touchstart', this.onDragStart)
            // events for drag end
            .on('mouseup', this.onDragEnd)
            .on('mouseupoutside', this.onDragEnd)
            .on('touchend', this.onDragEnd)
            .on('touchendoutside', this.onDragEnd)
            // events for drag move
            .on('mousemove', this.onDragMove)
            .on('touchmove', this.onDragMove)
    }

    
    onDragStart(event) : void
    {
        this.data = event.data;
        this.offset = this.data.getLocalPosition(this.parent);
        this.dragging = true;
        HSceneManager.INSTANCE.animate();
        this.onDragMove();
    }

    onDragEnd(): void
    {
        this.onDragMove();
        this.dragging = false;
        HSceneManager.INSTANCE.animate();
    }

    onDragMove() : void
    {
        if (this.dragging)
        {
            this.position.x += this.data.originalEvent.movementX;
            this.position.y += this.data.originalEvent.movementY;
            HSceneManager.INSTANCE.animate();
        }
    }
}
