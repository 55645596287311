import * as PIXI from 'pixi.js-legacy';
import { PCardModel } from '../piximodels/pcardmodel';
import { PGameState } from '../piximodels/pgamestate';
import { PTargetModel } from '../piximodels/ptargetmodel';

export class PCard extends PIXI.Sprite {
    type: number;
    level: number;
    subtype: string;
    description: string;
    targets: PTargetModel[];

    constructor(p_card_model:PCardModel){
        switch(p_card_model.type){
            case 0:
                super(PCard.LoadTexture('base_blue'));
                break;
            case 1:
                super(PCard.LoadTexture('base_green'));
                break;
            case 2:
                super(PCard.LoadTexture('base_red'));
                break;
            default:
                super()
                break;
        }

        this.level = p_card_model.level;
        this.type = p_card_model.type;
        this.subtype = p_card_model.subtype;
        this.description = p_card_model.description;

        this.targets = [];
        for(let i=0;i<p_card_model.target.length;i++){
            this.targets.push(PGameState.allTargets[p_card_model.target[i]])
        }

        this.loadLevelText();
        this.loadStars();
        this.loadTypeText();
        this.loadSubTypeText();
        this.loadBorders();
        this.loadHeaderText();
        this.loadDescriptionText();
        this.loadBackground();
        this.loadPictures();
        this.loadLabel();
        this.loadSuspectLabel();
        this.loadLink();
    }

    loadLevelText(){
        if (this.level == 0){
            this.addChild(PCard.LoadLevelNumber(this.level + '',194,304));
        } else {
            this.addChild(PCard.LoadLevelNumber(this.level + '',194,314));
        }
    }

    loadDescriptionText(){
        const text = new PIXI.Text(this.description, new PIXI.TextStyle({
            fontSize: 24,
            breakWords: true,
            wordWrap: true,
            wordWrapWidth: 380,
            align: "center",
        }));
        text.anchor.set(0.5,0.5);
        text.position.set(0,105);
        this.addChild(text);
    }

    loadSubTypeText(){
        const text = new PIXI.Text(this.subtype, new PIXI.TextStyle({
            fontSize: 32,
            fontWeight: "bold",
            fill: "#ffffff",
        }));
        text.anchor.set(0,0.5);
        text.position.set(-227,325);
        this.addChild(text);
    }

    loadTypeText(){
        let typeText = '';

        switch (this.type){
            case 0:
                typeText = 'Public Information';
                break;
            case 1:
                typeText = 'Financial Intelligence';
                break;
            case 2:
                typeText = 'Anti Corruption Agency';
                break;
            default:
                typeText = 'Undefined';
                break;
        }
        const text = new PIXI.Text(typeText, new PIXI.TextStyle({
            fontSize: 22,
        }));
        text.anchor.set(0,0.5);
        text.position.set(-225,275);
        this.addChild(text);
    }

    loadHeaderText(){
        let typeText = '';

        switch (this.type){
            case 0:
                typeText = 'Suspect';
                break;
            case 1:
                typeText = 'Money Flow';
                break;
            case 2:
                typeText = 'Suspects';
                break;
            default:
                typeText = 'Undefined';
                break;
        }
        const text = new PIXI.Text(typeText, new PIXI.TextStyle({
            fontWeight: "bold",
            fontSize: 38,
        }));
        text.anchor.set(0.5,0.5);
        text.position.set(0,-310);
        this.addChild(text);
    }

    loadStars(){
        if (this.level == 1){
            let star = PCard.LoadSprite('star',194,275);
            star.anchor.set(0.5,0.5);
            this.addChild(star);
        }
        if (this.level == 2){
            for(let i=0; i<this.level; i++){
                let star = PCard.LoadSprite('star',184 + (i*20),275);
                star.anchor.set(0.5,0.5);
                this.addChild(star);
            }
        }
    }

    loadBackground(){
        if (this.targets.length <= 1){
            let back = PCard.LoadSprite('suspect_back',0,-143);
            back.anchor.set(0.5,0.5);
            back.scale.set(1,0.9);
            this.addChild(back);
        } else {
            for (let i=0; i<2; i++){
                let back = PCard.LoadSprite('suspect_back',-107+(i*220),-143);
                back.anchor.set(0.5,0.5);
                back.scale.set(1,0.9);
                this.addChild(back);
            }
        }
    }

    loadPictures(){
        if (this.targets.length <= 1){
            let pic = PCard.LoadSprite(this.targets[0].resource,0,-143);
            pic.anchor.set(0.5,0.5);
            pic.scale.set(0.8,0.8);
            this.addChild(pic);
        } else {
            for (let i=0; i<2; i++){
                let pic = PCard.LoadSprite(this.targets[i].resource,-107+(i*220),-143);
                pic.anchor.set(0.5,0.5);
                pic.scale.set(0.8,0.8);
                this.addChild(pic);
            }
        }
    }

    loadSuspectLabel(){
        if (this.targets.length <= 1){
            const text = new PIXI.Text(this.targets[0].name, new PIXI.TextStyle({
                fontWeight: "bold",
                fontSize: 22,
                fill: "#ffffff",
                breakWords: true,
                wordWrap: true,
                wordWrapWidth: 150,
                lineHeight: 18,
                align: "center",
            }));
            
            text.anchor.set(0.5,0.5);
            text.position.set(-3,-42);
            this.addChild(text);
        } else {
            for (let i=0; i<2; i++){
                const text = new PIXI.Text(this.targets[i].name, new PIXI.TextStyle({
                    fontWeight: "bold",
                    fontSize: 22,
                    fill: "#ffffff",
                    breakWords: true,
                    wordWrap: true,
                    wordWrapWidth: 150,
                    lineHeight: 18,
                    align: "center",
                }));
                
                text.anchor.set(0.5,0.5);
                text.position.set(-110+(i*220),-42);
                this.addChild(text);
            }
        }
    }

    loadLink(){
        if (this.targets.length <= 1){
            return;
        }
        let linkResource = 'link_green';
        switch (this.type){
            case 1:
                linkResource = 'link_green';
                break;
            case 2:
                linkResource = 'link_red';
                break;
            default:
                linkResource = 'link_green';
                break;
        }

        let link = PCard.LoadSprite(linkResource,0,-150);
        link.anchor.set(0.5,0.5);
        link.scale.set(0.8,0.8);
        this.addChild(link);
    }

    loadLabel(){
        let labelResource = '';
        switch (this.type){
            case 0:
                labelResource = 'label_blue';
                break;
            case 1:
                labelResource = 'label_green';
                break;
            case 2:
                labelResource = 'label_red';
                break;
            default:
                labelResource = 'label_green';
                break;
        }

        if (this.targets.length <= 1){
            let label = PCard.LoadSprite(labelResource,-3,-42);
            label.anchor.set(0.5,0.5);
            label.scale.set(1.3,1.5);
            this.addChild(label);
        } else {
            for (let i=0; i<2; i++){
                let label = PCard.LoadSprite(labelResource,-110+(i*220),-42);
                label.anchor.set(0.5,0.5);
                label.scale.set(1.3,1.5);
                this.addChild(label);
            }
        }
    }

    loadBorders(){
        let leftBorder = 'borderleft_blue';
        let rightBorder = 'borderright_blue';

        switch (this.type){
            case 0:
                leftBorder = 'borderleft_blue';
                rightBorder = 'borderright_blue';
                break;
            case 1:
                leftBorder = 'borderleft_green';
                rightBorder = 'borderright_green';
                break;
            case 2:
                leftBorder = 'borderleft_red';
                rightBorder = 'borderright_red';
                break;
        }

        this.addChild(PCard.LoadSprite(leftBorder,-210,0));
        this.addChild(PCard.LoadSprite(rightBorder,165,180));
    }

    // Asset Management
    static CARD_ASSET_FOLDER = './../../assets/EvidenceCard_Parts';
    static PICTURE_ASSET_FOLDER = './../../assets/PhantomBilder_Klein';

    static AddResource(key: string, file: string): void {
        PIXI.Loader.shared.add('Card_' + key, PCard.CARD_ASSET_FOLDER + '/' + file);
    }

    static AddPicture(key: string, file: string): void {
        PIXI.Loader.shared.add('Card_' + key, PCard.PICTURE_ASSET_FOLDER + '/' + file);
    }

    static LoadResources(): void {
        PCard.AddResource('base_blue', 'Card_Base_Blue.png');
        PCard.AddResource('base_green', 'Card_Base_Green.png');
        PCard.AddResource('base_red', 'Card_Base_Red.png');
        PCard.AddResource('star', 'Stern.png');
        PCard.AddResource('borderleft_blue', 'Rahmen_Links_Blue.png');
        PCard.AddResource('borderleft_green', 'Rahmen_Links_Green.png');
        PCard.AddResource('borderleft_red', 'Rahmen_Links_Red.png');
        PCard.AddResource('borderright_blue', 'Rahmen_Rechts_Blue.png');
        PCard.AddResource('borderright_green', 'Rahmen_Rechts_Green.png');
        PCard.AddResource('borderright_red', 'Rahmen_Rechts_Red.png');
        PCard.AddResource('suspect_back', 'Card_SuspectBase.png');
        PCard.AddResource('label_blue', 'Card_SuspectLabel_Blue.png');
        PCard.AddResource('label_green', 'Card_SuspectLabel_Green.png');
        PCard.AddResource('label_red', 'Card_SuspectLabel_Red.png');
        PCard.AddResource('link_green', 'Arrow.png');
        PCard.AddResource('link_red', 'RelationSymbol.png');

        PCard.AddPicture('pic1', 'Face1.png');
        PCard.AddPicture('pic2', 'Face2.png');
        PCard.AddPicture('pic3', 'Face3.png');
        PCard.AddPicture('pic4', 'Face4.png');
        PCard.AddPicture('pic5', 'Face5.png');
        PCard.AddPicture('pic6', 'Face6.png');
        PCard.AddPicture('pic7', 'Face7.png');
        PCard.AddPicture('pic8', 'Face8.png');
        PCard.AddPicture('pic9', 'Face9.png');
        PCard.AddPicture('pic10', 'Face10.png');
        PCard.AddPicture('pic11', 'Face11.png');
        PCard.AddPicture('pic12', 'Face12.png');
        PCard.AddPicture('pic13', 'Face13.png');
        PCard.AddPicture('pic14', 'Face14.png');
        PCard.AddPicture('pic15', 'Face15.png');
        PCard.AddPicture('pic16', 'Face16.png');
        PCard.AddPicture('pic17', 'Face17.png');
        PCard.AddPicture('pic18', 'Face18.png');
        PCard.AddPicture('pic19', 'Face19.png');
        PCard.AddPicture('pic20', 'Face20.png');
        PCard.AddPicture('picnone', 'FaceNone.png');

        PCard.AddPicture('safe', 'Safe_Icon.png');
        PCard.AddPicture('suitcase', 'Suitcase_Icon.png');

        
        PCard.AddPicture('55789654', '55789654.png');
        PCard.AddPicture('ALKONIAComm', 'ALKONIAComm.png');
        PCard.AddPicture('Brookdale', 'Brookdale.png');
        PCard.AddPicture('Company', 'Company.png');
        PCard.AddPicture('DiamanteJewelers', 'DiamanteJewelers.png');
        PCard.AddPicture('FancomFinancialInvestmentBankingCompany', 'FancomFinancialInvestmentBankingCompany.png');
        PCard.AddPicture('HarvardUniversity', 'HarvardUniversity.png');
        PCard.AddPicture('JetwayTravelAgency', 'JetwayTravelAgency.png');
        PCard.AddPicture('JGConstruction', 'JGConstruction.png');
        PCard.AddPicture('KudzuMotorworks', 'KudzuMotorworks.png');
        PCard.AddPicture('MKLaw', 'MKLaw.png');
        PCard.AddPicture('MOF_ALTY', 'MOF_ALTY.png');
        PCard.AddPicture('NIS_ALTYIntelligence', 'NIS_ALTYIntelligence.png');
        PCard.AddPicture('NOC', 'NOC.png');
        PCard.AddPicture('OLCOOilConsultingFirm', 'OLCOOilConsultingFirm.png');
        PCard.AddPicture('Swoop', 'Swoop.png');

        PCard.AddPicture('BankOfALTY', 'BankOfALTY.png');
        PCard.AddPicture('ConGroupBank', 'ConGroupBank.png');
        PCard.AddPicture('CreditCards', 'CreditCards.png');
        PCard.AddPicture('CreditLyon', 'CreditLyon.png');
        PCard.AddPicture('CreditSuisse', 'CreditSuisse.png');
        PCard.AddPicture('FirstNationalBank', 'FirstNationalBank.png');
        PCard.AddPicture('MinistryOfNaturalResources', 'MinistryOfNaturalResources.png');
        PCard.AddPicture('SchweizBank', 'SchweizBank.png');
        PCard.AddPicture('StarBank', 'StarBank.png');
    }

    static LoadTexture(key: string): PIXI.Texture {
        return PIXI.Texture.from('Card_' + key);
    }

    static LoadSprite(key: string, x: number, y:number): PIXI.Sprite {
        const sprite = new PIXI.Sprite(PCard.LoadTexture(key));
        sprite.x = x;
        sprite.y = y;
        return sprite;
    }

    static LoadLevelNumber(stringText: string, x: number, y: number): PIXI.Text {
        const text = new PIXI.Text(stringText, new PIXI.TextStyle({
            fill: "#ffffff",
            fontWeight: "bold",
            fontSize: 52,
        }));
        text.anchor.set(0.5,0.5);
        text.position.set(x,y);
        return text;
    }


}
