import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { EScene } from '../enums/e-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';

export class StartMenu extends HScene implements IScene {
    UpdateScene(): void {}
    
    ASSET_FOLDER = this.ASSET_FOLDER + 'StartscreenPNGs';
    PREFIX = 'StartMenu_';

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);

        // Start Button
        const button = new PButton(
            this.LoadTexture('button'),
            this.LoadTexture('button_hover'),
            this.LoadTexture('button_click'),
            850, 662
        );
        button.on('click', (event) => {
            this.JumpToMainMenu();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(button);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'StartScreen_Background.png');
        this.AddResource('button', 'StartScreen_Startbutton.png');
        this.AddResource('button_hover', 'StartScreen_Startbutton_Hover.png');
        this.AddResource('button_click', 'StartScreen_Startbutton_Click.png');
    }

    JumpToMainMenu(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.MAINMENU);
    }

}
