<h2 mat-dialog-title>Request investigation on</h2>
<mat-dialog-content *ngIf="type == 1">
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let info of filterAccounts" [value]="info" (click)="close(info)">
            {{info.name}}
        </mat-list-option>
    </mat-selection-list>
    <p *ngIf="!(filterAccounts.length > 0)">You didn't discover any accounts yet!</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="type == 2">
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let info of filterSuspects" [value]="info" (click)="close(info)">
            {{info.name}}
        </mat-list-option>
    </mat-selection-list>
    <p *ngIf="!(filterSuspects.length > 0)">You didn't discover any suspects yet!</p>
</mat-dialog-content>
<mat-dialog-content *ngIf="type == 3">
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let info of filterAssets" [value]="info" (click)="close(info)">
            {{info.name}}
        </mat-list-option>
    </mat-selection-list>
    <p *ngIf="!(filterAssets.length > 0)">You didn't discover any assets yet!</p>
</mat-dialog-content>