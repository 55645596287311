import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { EScene } from '../enums/e-scene';
import { HSceneManager } from '../helpers/h-scenemanager';

export class SettingsScreen extends HScene implements IScene {
    UpdateScene(): void {}
    ASSET_FOLDER = this.ASSET_FOLDER + 'SettingsPNGs';
    PREFIX = 'SettingsScreen_';
    static FULLSCREEN = 0;

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const background_paper = this.LoadSprite('background_paper',960,540);
        background_paper.anchor.set(0.5,0.5);

        const header = this.LoadSprite('header',30,-390);
        header.anchor.set(0.5,0.5);

        const description = this.LoadSprite('description',30,-230);
        description.anchor.set(0.5,0.5);

        const fullscreenbutton = new PButton(
            this.LoadTextureDependency('fullscreenbutton','fullscreenbutton_active',(SettingsScreen.FULLSCREEN == 1)),
            this.LoadTextureDependency('fullscreenbutton_hover','fullscreenbutton_active_click',(SettingsScreen.FULLSCREEN == 1)),
            this.LoadTextureDependency('fullscreenbutton_click','fullscreenbutton_active_hover',(SettingsScreen.FULLSCREEN == 1)),
            30, 20
        );
        fullscreenbutton.anchor.set(0.5,0.5);

        fullscreenbutton.on('click',(event) => {
            if (SettingsScreen.FULLSCREEN == 0){
                this.DoFullScreen();
            } else {
                this.CloseFullscreen();
            }
            this.Reload();
        });

        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back'),
            this.LoadTexture('back_click'),
            30, 325
        );
        back.anchor.set(0.5,0.5);

        back.on('click',(event) => {
            this.GoToMainMenu();
        });

        // Add Elements to Canvas
        container.addChild(background);
        background_paper.addChild(back);
        background_paper.addChild(header);
        background_paper.addChild(fullscreenbutton);
        background_paper.addChild(description);
        container.addChild(background_paper);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'SettingBG.png');
        this.AddResource('background_paper', 'Settings_Background.png');
        this.AddResource('back', 'Settings_BackButton.png');
        this.AddResource('back_click', 'Settings_BackButton_Click.png');
        this.AddResource('header', 'SettingsHeadline.png');
        this.AddResource('description', 'Settings_Text.png');
        this.AddResource('fullscreenbutton_active', 'FullScreenButtonSelected_Default.png');
        this.AddResource('fullscreenbutton_active_click', 'FullScreenButtonSelected_Click.png');
        this.AddResource('fullscreenbutton_active_hover', 'FullScreenButtonSelected_Hover.png');
        this.AddResource('fullscreenbutton', 'FullScreenButtonDeselected_Default.png');
        this.AddResource('fullscreenbutton_click', 'FullScreenButtonDeselected_Click.png');
        this.AddResource('fullscreenbutton_hover', 'FullScreenButtonDeselected_Hover.png');
    }

    GoToMainMenu(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.MAINMENU);
    }

    Reload(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.SETTINGSSCREEN);
    }

    GetSettingsHeader(): string {
        return 'Settings';
    }

    GetSettingsHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }

    GetSettingsLabelStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 26
        });
    }

    DoFullScreen(): void {
        var elem: any = document.getElementsByTagName('app-root')[0];
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { /* Safari */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE11 */
            elem.msRequestFullscreen();
        }
        SettingsScreen.FULLSCREEN = 1;
    }

    CloseFullscreen() {
        var doc: any = document;
        if (doc.exitFullscreen) {
            doc.exitFullscreen();
        } else if (doc.webkitExitFullscreen) { /* Safari */
            doc.webkitExitFullscreen();
        } else if (doc.msExitFullscreen) { /* IE11 */
            doc.msExitFullscreen();
        }
        SettingsScreen.FULLSCREEN = 0;
      }

    LoadTextureDependency(key1: string, key2: string, check: boolean): PIXI.Texture {
        if (check){
            return this.LoadTexture(key2);
        } else {
            return this.LoadTexture(key1);
        }
    }
}
