import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { PSideNavigation } from '../pixielements/psidenavigation';
import { PPhoneAddressScreen } from '../pixielements/pphoneaddress';
import { PPhoneCallScreen } from '../pixielements/pphonecall';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';

export class TelephoneScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'NahansichtenPNGs/Phone';
    PREFIX = 'Phone_';
    MENU = new PSideNavigation(3,this);
    
    PHONE: PPhoneAddressScreen = null;
    PhoneView: PIXI.Container;

    CONVERSATION: PPhoneCallScreen = null;
    ConversationView: PIXI.Container;

    constructor(){
        super();
        this.PHONE = new PPhoneAddressScreen();
        this.CONVERSATION = new PPhoneCallScreen();
    }

    UpdateScene(): void {}

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);

        this.PHONE.ConnectFallback(() => {
            this.PhoneView.visible = !this.PhoneView.visible;
            HSceneManager.INSTANCE.animate();
        },
        (data) => {
            this.PhoneView.visible = !this.PhoneView.visible;
            this.CONVERSATION.ChangeHeader(data);
            this.ConversationView.visible = !this.PhoneView.visible;
            HSceneManager.INSTANCE.animate();
        });
        this.PhoneView = this.PHONE.BuildScene();
        this.PhoneView.interactive = true;
        this.PhoneView.visible = false;

        this.CONVERSATION.ConnectFallback(() => {
            this.ConversationView.visible = !this.ConversationView.visible;
            HSceneManager.INSTANCE.animate();
        });
        this.ConversationView = this.CONVERSATION.BuildScene();
        this.ConversationView.interactive = true;
        this.ConversationView.visible = false;

        // computer Button
        const addressbook = new PButton(
            this.LoadTexture('addressbook'),
            this.LoadTexture('addressbook_hover'),
            this.LoadTexture('addressbook_click'),
            960, 942
        );
        addressbook.anchor.set(0.5,0.5);

        addressbook.on('click', (event) => {
            this.PhoneView.visible = true;
            HSceneManager.INSTANCE.animate();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(addressbook);
        container.addChild(this.MENU.BuildScene());
        container.addChild(this.PhoneView);
        container.addChild(this.ConversationView);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'Phone.png');
        this.AddResource('addressbook', 'AddressBookButton.png');
        this.AddResource('addressbook_hover', 'AddressBookButton_Hover.png');
        this.AddResource('addressbook_click', 'AddressBookButton_Click.png');
        this.MENU.LoadResources();
        this.PHONE.LoadResources();
        this.CONVERSATION.LoadResources();
    }
}
