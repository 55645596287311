import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-selection-type',
  templateUrl: './filter-selection-type.component.html',
  styleUrls: ['./filter-selection-type.component.css']
})
export class FilterSelectionTypeComponent implements OnInit {

  filterTypes = [
    {name: 'All', value: -1},
    {name: 'Public Information', value: 0},
    {name: 'Financial Investigation', value: 1},
    {name: 'Anti Corruption Agency', value: 2},
    {name: 'Seizable Assets', value: 3}
  ];
  CALLBACK: (name,value) => void = null;

  dialog: MatDialog;
  constructor(dialog: MatDialog) {
    this.dialog = dialog;
  }

  ngOnInit(): void {
  }

  close(info: any){
    this.CALLBACK(info.name,info.value);
    this.dialog.closeAll();
  }


}
