export enum EScene {
    STARTMENU,
    MAINMENU,
    NEWGAMESCREEN,
    BRIEFINGSCREEN,
    OFFICESCREEN,
    COMPUTERSCREEN,
    FILEFOLDERSCREEN,
    TELEPHONESCREEN,
    WHITEBOARDSCREEN,
    SETTINGSSCREEN,
    LOADGAMESCREEN,
    LOADINGSCREEN,
    SCORESCREEN
};