<h1 mat-dialog-title>Library</h1>
<mat-dialog-content>
    <mat-selection-list [multiple]="false">
        <mat-list-option *ngFor="let pdf of pdfs" [value]="pdf" (click)="openpdf(pdf)" mat-dialog-close>
            {{pdf.Name}}
        </mat-list-option>
    </mat-selection-list>
</mat-dialog-content>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
</div>
