import * as PIXI from 'pixi.js-legacy';
import { HScene } from '../helpers/h-scene';
import { IScene } from '../interfaces/i-scene';
import { PButton } from './pbutton';
import { PGameState } from './../piximodels/pgamestate';

export class PCharacterScreen extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/CharacterScreenPNGs';
    PREFIX = 'CharacterScreen';
    CALLBACK: () => void = null;
    UPDATE: () => void = null;
    HEADER = null;
    CHARASSET = null;
    DESCRIPTION = null;

    constructor(){
        super();
    }

    ConnectFallback(connectFeedback: () => void,connectChange: () => void): void {
        this.CALLBACK = connectFeedback;
        this.UPDATE = connectChange;
    }
    
    UpdateScene(): void {}

    BuildScene(): PIXI.Container {
        const view = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        const klemm = this.LoadSprite('klemm',621,111);

        this.CHARASSET = this.LoadSprite(this.GetCharAsset(),1340,660);
        this.CHARASSET.anchor.set(0.5,0.5);

        // back Button
        /*const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            960, 825
        );
        back.anchor.set(0.5,0.5);
        back.on('click', (event) => {
            this.CALLBACK();
        });*/

        // confirm Button
        const confirm = new PButton(
            this.LoadTexture('confirm'),
            this.LoadTexture('confirm_hover'),
            this.LoadTexture('confirm_click'),
            960, 825
        );
        confirm.anchor.set(0.5,0.5);
        confirm.on('click', (event) => {
            this.CALLBACK();
        });

        // left Button
        const left = new PButton(
            this.LoadTexture('left'),
            this.LoadTexture('left_hover'),
            this.LoadTexture('left_click'),
            750, 970
        );
        left.anchor.set(0.5,0.5);
        left.on('click', (event) => {
            PGameState.character--;
            if (PGameState.character<0){
                PGameState.character = 2;
            }
            this.HEADER.text = this.GetBriefingHeader();
            this.DESCRIPTION.text = this.GetDescText();
            this.CHARASSET.texture = this.LoadTexture(this.GetCharAsset());
            this.UPDATE();
        });

        // right Button
        const right = new PButton(
            this.LoadTexture('right'),
            this.LoadTexture('right_hover'),
            this.LoadTexture('right_click'),
            1170, 970
        );
        right.anchor.set(0.5,0.5);
        right.on('click', (event) => {
            PGameState.character++;
            if (PGameState.character>2){
                PGameState.character = 0;
            }
            this.HEADER.text = this.GetBriefingHeader();
            this.DESCRIPTION.text = this.GetDescText();
            this.CHARASSET.texture = this.LoadTexture(this.GetCharAsset());
            this.UPDATE();
        });

        this.HEADER = this.LoadText(this.GetBriefingHeader(), 100, 175);
        this.HEADER.style = this.GetBriefingHeaderStyle();

        this.DESCRIPTION = this.LoadText(this.GetDescText(), 100, 245);
        this.DESCRIPTION.style = this.GetDescTextStyle();

        view.addChild(background);
        view.addChild(klemm);
        klemm.addChild(this.DESCRIPTION);
        klemm.addChild(this.HEADER);
        view.addChild(this.CHARASSET);
        view.addChild(confirm);
        view.addChild(left);
        view.addChild(right);

        return view;
    }

    LoadResources(): void {
        if (PCharacterScreen.loaded){
            return;
        }
        this.AddResource('background', 'Background.png');
        this.AddResource('klemm', 'Klemmbrett.png');

        this.AddResource('back', 'BackButton.png');
        this.AddResource('back_hover', 'BackButton_Hover.png');
        this.AddResource('back_click', 'BackButton_Click.png');
        
        this.AddResource('confirm', 'ConfirmButton.png');
        this.AddResource('confirm_hover', 'ConfirmButton_Hover.png');
        this.AddResource('confirm_click', 'ConfirmButton_Click.png');

        this.AddResource('ACA', 'ACA.png');
        this.AddResource('FIA', 'FIA.png');
        this.AddResource('PP', 'PP.png');
        
        this.AddResource('left', 'PostIt_PfeilLinks_Default.png');
        this.AddResource('left_hover', 'PostIt_PfeilLinks_hover.png');
        this.AddResource('left_click', 'PostIt_PfeilLinks_Click.png');
        
        this.AddResource('right', 'PostIt_PfeilRechts_Default.png');
        this.AddResource('right_hover', 'PostIt_PfeilRechts_hover.png');
        this.AddResource('right_click', 'PostIt_PfeilRechts_Click.png');
        PCharacterScreen.loaded = true;
    }

    GetBriefingHeader(): string {
        switch (PGameState.character){
            case 0:
                return 'Anti Corruption Specialist';
            case 1:
                return 'Finance Specialist';
            case 2:
                return 'Public Prosecutor';
            default:
                return 'Anti Corruption Specialist';
        }
    }

    GetCharAsset(): string {
        switch (PGameState.character){
            case 0:
                return 'ACA';
            case 1:
                return 'FIA';
            case 2:
                return 'PP';
            default:
                return 'ACA';
        }
    }

    GetBriefingHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }

    GetDescText(): string {
        switch (PGameState.character){
            case 0:
                return 'The Anti-Corruption Specialist is responsible for gathering information about suspects, through interviews, surveys and investigations.';
            case 1:
                return 'The Financial Crime Specialist is responsible for gathering financial intelligence by making requests for all financial records that will assist the investigations of alleged corruption activities.';
            case 2:
                return 'The Public Prosecutor is responsible for dealing with the courts and preparing the case.\nThey are the only one who are eligible to hand in the final results of the case.';
            default:
                return 'Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat.';
        }

    }

    GetDescTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontSize: 26
        });
    }
}
