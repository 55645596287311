import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogMessageComponent } from '../error-dialog-message/error-dialog-message.component';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';

@Component({
  selector: 'app-confirm-points-dialog',
  templateUrl: './confirm-points-dialog.component.html',
  styleUrls: ['./confirm-points-dialog.component.css']
})
export class ConfirmPointsDialogComponent implements OnInit {

  steps: number;
  CALLBACK: () => void = null;
  dialog: MatDialog;

  constructor() { }

  setData(steps: number, dialog: MatDialog) {
    this.steps = steps;
    this.dialog = dialog;
  }

  ngOnInit(): void {
  }

  doAction(): void {
    this.dialog.closeAll();
    if (this.check()){
      this.removePoints();
      this.CALLBACK();
    } else {
      var errorMessage = this.dialog.open(ErrorDialogMessageComponent);
      errorMessage.componentInstance.message = "You do not have enough Steps to perform this action";
    }
    HSceneManager.INSTANCE.animate();
  }

  removePoints(): void {
    PGameState.steps -= this.steps;
    if (PGameState.steps<=0){
      PGameState.steps = 0;
    }
    HSceneManager.INSTANCE.animate();
  }

  check():boolean{
    if ((PGameState.steps - this.steps)<0){
      return false;
    }
    return true;
  }



}
