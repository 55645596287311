import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { EScene } from '../enums/e-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';
import { MatDialog } from '@angular/material/dialog';
import { NyiComponent } from '../nyi/nyi.component';

export class NewGameScreen extends HScene implements IScene {
    UpdateScene(): void {}
    ASSET_FOLDER = this.ASSET_FOLDER + 'StartGameScreenPNGs';
    PREFIX = 'NewGameScreen_';

    dialog: MatDialog;

    constructor(dialog: MatDialog){
        super();
        this.dialog = dialog;
    }

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const gamemode = this.LoadSprite('gamemode',590,45);

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            35, 825
        );
        back.on('click', (event) => {
            this.GoBack();
        });

        // level1 Button
        const level1 = new PButton(
            this.LoadTexture('level1'),
            this.LoadTexture('level1_hover'),
            this.LoadTexture('level1_click'),
            162, 245
        );
        level1.on('click',(event) => {
            PGameState.newData(1);
            this.GoToBriefing();
        });

        // level2 Button
        const level2 = new PButton(
            this.LoadTexture('level2'),
            this.LoadTexture('level2_hover'),
            this.LoadTexture('level2_click'),
            700, 290
        );
        level2.on('click',(event) => {
            PGameState.newData(2);
            this.GoToBriefing();
        });

        // level3 Button
        const level3 = new PButton(
            this.LoadTexture('level3'),
            this.LoadTexture('level3_hover'),
            this.LoadTexture('level3_click'),
            1250, 265
        );
        level3.on('click',(event) => {
            PGameState.newData(3);
            this.GoToBriefing();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(level1);
        container.addChild(level2);
        //container.addChild(level3);
        container.addChild(back);
        container.addChild(gamemode);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'StartGame_Background.png');
        this.AddResource('back', 'StartGame_BackButton.png');
        this.AddResource('back_click', 'StartGame_BackButton_Click.png');
        this.AddResource('back_hover', 'StartGame_BackButton_Hover.png');
        this.AddResource('level1', 'StartGame_Level1.png');
        this.AddResource('level1_click', 'StartGame_Level1_Click.png');
        this.AddResource('level1_hover', 'StartGame_Level1_Hover.png');
        this.AddResource('level2', 'StartGame_Level2.png');
        this.AddResource('level2_click', 'StartGame_Level2_Click.png');
        this.AddResource('level2_hover', 'StartGame_Level2_Hover.png');
        this.AddResource('level3', 'StartGame_Level3.png');
        this.AddResource('level3_click', 'StartGame_Level3_Click.png');
        this.AddResource('level3_hover', 'StartGame_Level3_Hover.png');
        this.AddResource('next', 'StartGame_NextButton.png');
        this.AddResource('next_click', 'StartGame_NextButton_Click.png');
        this.AddResource('next_hover', 'StartGame_NextButton_Hover.png');
        this.AddResource('gamemode', 'StartGame_PickGameMode.png');
    }

    GoBack(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.MAINMENU);
    }

    GoToBriefing(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.BRIEFINGSCREEN);
    }
}
