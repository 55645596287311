import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { EScene } from '../enums/e-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';

export class ScoreScreen extends HScene implements IScene {
    UpdateScene(): void {}
    public static INSTANCE: IScene = null;
    ASSET_FOLDER = this.ASSET_FOLDER + 'ScoreScreenPNGs';
    PREFIX = 'ScoreScreen_';

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const moderator = this.LoadSprite('moderator', 1270, 25);
        const klemmbrett = this.LoadSprite('klemmbrett', 400, 25);
        const description = this.LoadText(this.GetBriefingText(), 100, 305);
        description.style = this.GetBriefingTextStyle();
        const scorepoints = this.LoadText(this.GetScoreText(), 430, 305);
        scorepoints.style = this.GetBriefingTextStyle();
        const scorehead = this.LoadText(this.GetBriefingHead(), 100, 265);
        scorehead.style = this.GetBriefingTextBoldStyle();
        const scoreheadpoints = this.LoadText(this.GetScoreHeadText(), 430, 265);
        scoreheadpoints.style = this.GetBriefingTextBoldStyle();
        const assets = this.LoadText("No Assets seized!", 100, 600);
        assets.style = this.GetBriefingTextBoldStyle();
        const bonus = this.LoadText("No Bonus Points!", 100, 630);
        bonus.style = this.GetBriefingTextBoldStyle();
        const totalscore = this.LoadText("Total Score .........................", 100, 800);
        totalscore.style = this.GetBriefingTextBoldStyle();
        const totalscorepoints = this.LoadText(this.GetScorePrecentageText(), 430, 800);
        totalscorepoints.style = this.GetBriefingTextBoldStyle();
        const header = this.LoadText(this.GetBriefingHeader(), 100, 175);
        header.style = this.GetBriefingHeaderStyle();
        let stamp = this.LoadSprite('failed', 560, 405);

        let unlocked = PGameState.unlockedCards.length/PGameState.allCards.length;

        if (unlocked > 0.5 && unlocked <= 0.7){ 
            stamp = this.LoadSprite('ok', 560, 405);
        }
        if (unlocked > 0.7 && unlocked <= 0.9){ 
            stamp = this.LoadSprite('good', 560, 405);
        }
        if (unlocked > 0.9){ 
            stamp = this.LoadSprite('great', 560, 405);
        }

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            35, 825
        );
        back.on('click', (event) => {
            this.GoToOffice();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(moderator);
        klemmbrett.addChild(header);
        klemmbrett.addChild(description);
        klemmbrett.addChild(scorepoints);
        klemmbrett.addChild(scorehead);
        klemmbrett.addChild(scoreheadpoints);
        klemmbrett.addChild(bonus);
        klemmbrett.addChild(assets);
        klemmbrett.addChild(totalscore);
        klemmbrett.addChild(totalscorepoints);
        klemmbrett.addChild(stamp);
        container.addChild(klemmbrett);
        container.addChild(back);
        //container.addChild(next);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'BriefingScreen_Background.png');
        this.AddResource('moderator', 'Moderator_CoatBrown.png');
        this.AddResource('klemmbrett', 'BriefingScreen_Klemmbrett.png');
        this.AddResource('back', 'BriefingScreen_BackButton.png');
        this.AddResource('back_click', 'BriefingScreen_BackButton_Click.png');
        this.AddResource('back_hover', 'BriefingScreen_BackButton_Hover.png');
        this.AddResource('next', 'BriefingScreen_NextButton.png');
        this.AddResource('next_click', 'BriefingScreen_NextButton_Click.png');
        this.AddResource('next_hover', 'BriefingScreen_NextButton_Hover.png');
        this.AddResource('failed', 'FailedStamp.png');
        this.AddResource('ok', 'OKStamp.png');
        this.AddResource('good', 'GoodStamp.png');
        this.AddResource('great', 'GreatStamp.png');
    }

    GoToOffice(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.OFFICESCREEN);
    }

    GetBriefingHead(): string {
        return 'Total Evidences .. '+ (PGameState.getCount(2,1) + PGameState.getCount(2,2) + PGameState.getCount(1,2) )+'x\r';
    }

    GetBriefingText(): string {
        return 'ACA Level 1 ........  '+ PGameState.getCount(2,1)+'x\r'
                + 'ACA Level 2 ........  '+ PGameState.getCount(2,2)+'x\r'
                + 'FI .........................  '+ PGameState.getCount(1,2)+'x\r';
    }

    GetScoreText(): string {
        return '.......................... '+ (PGameState.getCount(2,1)* 3)+' Pts.\r'
                + '.......................... '+ (PGameState.getCount(2,2)* 7)+' Pts.\r'
                + '.......................... '+ (PGameState.getCount(1,2)* 7)+' Pts.\r';
    }

    GetScoreHeadText(): string {
        return '.......................... '+ ((PGameState.getCount(2,1)*3) + (PGameState.getCount(2,2)*7) + (PGameState.getCount(1,2)*7) )+' Pts.\r';
    }

    GetScorePrecentageText(): string {
        let unlocked = Math.round((PGameState.unlockedCards.length/PGameState.allCards.length) * 100);
        return '.......................... '+ unlocked +' %\r';
    }

    GetBriefingHeader(): string {
        return 'Your Performance';
    }

    GetBriefingTextBoldStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 597,
            fontSize: 26,
            fontWeight: 'bold'
        });
    }

    GetBriefingTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 597,
            fontSize: 26
        });
    }

    GetBriefingHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }
}
