export class PTargetModel {
    public id = 0;
    public type = 0;
    public resource = "pic1";
    public name = "Name";
    public aca1 = -1;
    public aca2 = -1;
    public pi = -1;
    public fi = -1;
    public difficulty = 0;
    public additionalCardrequirements:number[] = [];
}