import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { EScene } from '../enums/e-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { ComputerScreen } from './computerscreen';
import { PGameState } from '../piximodels/pgamestate';

export class BriefingScreen extends HScene implements IScene {
    UpdateScene(): void {}
    public static INSTANCE: IScene = null;
    ASSET_FOLDER = this.ASSET_FOLDER + 'BriefingScreenPNGs';
    PREFIX = 'BriefingScreen_';

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const moderator = this.LoadSprite('moderator', 1270, 25);
        const klemmbrett = this.LoadSprite('klemmbrett', 400, 25);
        const description = this.LoadText(this.GetBriefingText(), 100, 245);
        description.style = this.GetBriefingTextStyle();
        const header = this.LoadText(this.GetBriefingHeader(), 100, 175);
        header.style = this.GetBriefingHeaderStyle();

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            35, 825
        );
        back.on('click', (event) => {
            this.GoBack();
        });

        // next Button
        const next = new PButton(
            this.LoadTexture('next'),
            this.LoadTexture('next_hover'),
            this.LoadTexture('next_click'),
            1665, 825
        );
        next.on('click', (event) => {
            this.GoToOffice();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(moderator);
        klemmbrett.addChild(header);
        klemmbrett.addChild(description);
        container.addChild(klemmbrett);
        //container.addChild(back);
        container.addChild(next);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'BriefingScreen_Background.png');
        this.AddResource('moderator', 'Moderator_CoatBrown.png');
        this.AddResource('klemmbrett', 'BriefingScreen_Klemmbrett.png');
        this.AddResource('back', 'BriefingScreen_BackButton.png');
        this.AddResource('back_click', 'BriefingScreen_BackButton_Click.png');
        this.AddResource('back_hover', 'BriefingScreen_BackButton_Hover.png');
        this.AddResource('next', 'BriefingScreen_NextButton.png');
        this.AddResource('next_click', 'BriefingScreen_NextButton_Click.png');
        this.AddResource('next_hover', 'BriefingScreen_NextButton_Hover.png');
    }

    GoBack(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.NEWGAMESCREEN);
    }

    GoToOffice(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.COMPUTERSCREEN);
        let screen:ComputerScreen = (HSceneManager.INSTANCE.SCENES[EScene.COMPUTERSCREEN] as ComputerScreen);
        screen.openEmails();
    }

    GetBriefingText(): string {
        if (PGameState.level == 1){  
            return 'Welcome to the Follow the Money Introduction Level! In this scenario, the company MyFrogs.com created an unsual amount of sales this year without making any actual revenue.\r\rDuring this investment you are going to learn the core elements of investigation in the fictional country of Alkonia!';
        } else if (PGameState.level == 2) {
            return 'Welcome to the Follow the Money Introduction Level! In this scenario, the company GasEmpire created an unsual amount of sales this year without making any actual revenue.\r\rDuring this investment you are going to learn the core elements of investigation in the fictional country of Alkonia!';
        } else {
            return 'Pursuant to a lead from the media and secret information received to the effect that monies have been embezzled from a government account (DONTOP) maintained at a foreign branch of the National Bank of Alkonia, investigation was started. Since the case involved significant financial transactions and money laundering operations, therefore, the FIU Alkonia was contacted to provide their technical support. A joint team was constituted under the supervision of Mr. Toolani Papa to investigate into the matter.';
        }
    }

    GetBriefingHeader(): string {
        return 'Case Information';
    }

    GetBriefingTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 597,
            fontSize: 26
        });
    }

    GetBriefingHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }
}
