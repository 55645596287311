import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';

import * as PIXI from 'pixi.js-legacy';

export class LoadScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'LoadingScreen';
    PREFIX = 'LoadScreen_';

    UpdateScene(): void {}
    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);

        const randomChoice = this.GetRandomInt(3);
        let char: PIXI.Sprite = null;
        if (randomChoice == 0){
            char = this.LoadSprite('char1',1500,75);
        } else if (randomChoice == 1) {
            char = this.LoadSprite('char2',200,75);
        } else {
            char = this.LoadSprite('char3',1500,75);
        }

        const info = this.LoadText("Loading...", 1755, 1030);
        info.anchor.set(0.5,0.5);   
        info.style = this.GetTextStyle();

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(char);
        container.addChild(info);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'LadebildschirmOhneCharakter.png');
        this.AddResource('char1', 'AntiCorruptionSpecialist_Female_Coloured.png');
        this.AddResource('char2', 'FinanceSpecialistNew.png');
        this.AddResource('char3', 'PublicProsecutor_Colour_Changed.png');
    }

    
    GetTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 40,
            fill: 0xFFFFFF,
            dropShadow: true,
            stroke: 'black',
            strokeThickness: 2
        });
    }

    GetRandomInt(max: number) {
        return Math.floor(Math.random() * Math.floor(max));
      }
}
