import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { PGameState } from '../piximodels/pgamestate';

export class ComputerWebScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'News';
    PREFIX = 'ComputerWebScreen';
    CONTENT = null;

    UpdateScene(): void {
        if (this.CONTENT != null){
            this.CONTENT.text = this.GetBriefingText();
        }
    }

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',431,121);

        this.CONTENT = this.LoadText(this.GetBriefingText(), 780, 520);
        this.CONTENT.style = this.GetContentStyle();

        var title = this.LoadText("Investigation started!", 780, 490);
        title.style = this.GetHeaderStyle();

        var title2 = this.LoadText("First monkey on the moon!", 780, 725);
        title2.style = this.GetHeaderStyle();

       //const hint = this.LoadSprite('hint',1180,600);

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(this.CONTENT);
        container.addChild(title);
        container.addChild(title2);
        //container.addChild(hint);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'News_Vorlage.png');
        this.AddResource('hint', 'hint.png');
    }

    GetHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 18,
            fontWeight: 'bold'
        });
    }

    GetBriefingText(): string {
        let unlocked = PGameState.unlockedCards.length/PGameState.allCards.length;

        if (PGameState.level == 1){
            if (unlocked > 0.1 && unlocked <= 0.5){ 
                return 'Investigators have made their first progress regarding some suspicius activities from MyFrogs.com\rThe company of Mrs. Marina recently got into the crosshairs of anti corruption specialists.\rNo statement from Frogstar was given at this moment.';
            }
            if (unlocked > 0.5 && unlocked <= 0.7){ 
                return 'Investigations involving MyFrogs.com discovered possible involvement of Frogstar Energy and Macrosoft.\rWhile further details are not know at this moment, it seems that investigations\rare progressing at a rapid rate.';
            }
            if (unlocked > 0.7 && unlocked <= 0.9){ 
                return 'Macrosofts CEO Mr. Marc denies any involvement in any money laundering\rinvolving MyFrogs.com and Frogstar Energy.\r ';
            }
            if (unlocked > 0.9){ 
                return 'With new evidence surfacing, the investigation of\rMyFrogs.com,\rMacrosof\r and Frogstar Energy is almost complete\rIt is only a matter of time until further legal actions are being charged.';
            }
            return 'According to rumors, MyFrogs.com created an unsual amount of sales this year\rwithout making any actual revenue.\rA joint team was constituted under the supervision of Mr. Toolani Papa to investigate into the matter.';
        } else if (PGameState.level == 2){
            if (unlocked > 0.1 && unlocked <= 0.5){ 
                return 'Investigators have made their first progress regarding some suspicius activities from GasEmpire\rThe company of Mrs. MARKEL recently got into the crosshairs of anti corruption specialists.\rNo statement from GasEmpire was given at this moment.';
            }
            if (unlocked > 0.5 && unlocked <= 0.7){ 
                return 'Investigations involving GasEmpire discovered possible involvement of GamesDoctor and PulpinDream.\rWhile further details are not know at this moment, it seems that investigations\rare progressing at a rapid rate.';
            }
            if (unlocked > 0.7 && unlocked <= 0.9){ 
                return 'PulpinDreams CEO Mr. NIGHTRIDER denies any involvement in any money laundering\rinvolving GasEmpire and GamesDoctor.\r';
            }
            if (unlocked > 0.9){ 
                return 'With new evidence surfacing, the investigation of\rGasEmpire,\rPulpinDream\r and GamesDoctor is almost complete\rIt is only a matter of time until further legal actions are being charged.';
            }
            return 'According to rumors, GasEmpire created an unsual amount of sales this year\rwithout making any actual revenue.\rA joint team was constituted under the supervision of Mrs. Migatsuki to investigate into the matter.';
        }
        return 'Pursuant to a lead from the media and secret information received to the effect that monies have been\r\nembezzled from a government account (DONTOP) maintained at a foreign branch\r\nof the National Bank of Alkonia,investigation was started.\r\nSince the case involved significant financial transactions and money laundering operations,\r\ntherefore, the FIU Alkonia was contacted to provide their technical support.\r\nA joint team was constituted under the supervision of Mr. Toolani Papa to investigate into the matter.';
    }

    GetContentStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 14,
            fontWeight: 'normal',
            wordWrapWidth: 4,
        });
    }
}
