import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-selection-level',
  templateUrl: './filter-selection-level.component.html',
  styleUrls: ['./filter-selection-level.component.css']
})
export class FilterSelectionLevelComponent implements OnInit {

  filterLevels = [
    {name: 'All', value: -1},
    {name: 'Level 0', value: 0},
    {name: 'Level 1', value: 1},
    {name: 'Level 2', value: 2}
  ];
  CALLBACK: (name,value) => void = null;

  dialog: MatDialog;
  constructor(dialog: MatDialog) {
    this.dialog = dialog;
  }

  ngOnInit(): void {
  }

  close(info: any){
    this.CALLBACK(info.name,info.value);
    this.dialog.closeAll();
  }

}
