import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { PSideNavigation } from '../pixielements/psidenavigation';
import { ComputerDesktopScreen } from './computerdesktopscreen';
import { ComputerEmailScreen } from './computeremailscreen';
import { ComputerWebScreen } from './computerwebscreen';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';

export class ComputerScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'NahansichtenPNGs/Computer';
    PREFIX = 'ComputerScreen_';
    MENU = new PSideNavigation(0,this);

    DESKTOPSCREEN = new ComputerDesktopScreen();
    DESKTOPSCREENESCENE = null;

    EMAILSCREEN = new ComputerEmailScreen();
    EMAILSCREENSCENE = null;

    WEBSCREEN = new ComputerWebScreen();
    WEBSCREENSCENE = null;

    BACKBUTTON = null;

    UpdateScene(): void {}

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const white = this.LoadSprite('white',395,84);

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(white);

        this.DESKTOPSCREEN.ConnectFallback(() => {
            this.openEmails();
        },()=>{
            this.WEBSCREEN.UpdateScene();
            this.DESKTOPSCREENESCENE.visible = false;
            this.DESKTOPSCREENESCENE.interactive = false;
            this.EMAILSCREENSCENE.visible = false;
            this.EMAILSCREENSCENE.interactive = false;
            this.WEBSCREENSCENE.visible = true;
            this.WEBSCREENSCENE.interactive = true;
            this.BACKBUTTON.interactive = true;
            this.BACKBUTTON.visible = true;
            HSceneManager.INSTANCE.animate();
        });

        this.DESKTOPSCREENESCENE = this.DESKTOPSCREEN.BuildScene();
        this.DESKTOPSCREENESCENE.interactive = true;
        this.DESKTOPSCREENESCENE.visible = true;
        container.addChild(this.DESKTOPSCREENESCENE);

        this.EMAILSCREENSCENE = this.EMAILSCREEN.BuildScene();
        this.EMAILSCREENSCENE.interactive = false;
        this.EMAILSCREENSCENE.visible = false;
        container.addChild(this.EMAILSCREENSCENE);

        this.WEBSCREENSCENE = this.WEBSCREEN.BuildScene();
        this.WEBSCREENSCENE.interactive = false;
        this.WEBSCREENSCENE.visible = false;
        container.addChild(this.WEBSCREENSCENE);

        this.BACKBUTTON = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            460, 890
        );
        
        this.BACKBUTTON.interactive = false;
        this.BACKBUTTON.visible = false;
        this.BACKBUTTON.on('click', (event) => {
            this.DESKTOPSCREENESCENE.visible = true;
            this.DESKTOPSCREENESCENE.interactive = true;
            this.EMAILSCREENSCENE.visible = false;
            this.EMAILSCREENSCENE.interactive = false;
            this.WEBSCREENSCENE.visible = false;
            this.WEBSCREENSCENE.interactive = false;
            this.BACKBUTTON.interactive = false;
            this.BACKBUTTON.visible = false;
            HSceneManager.INSTANCE.animate();
        });

        container.addChild(this.BACKBUTTON);
        container.addChild(this.MENU.BuildScene());
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'Computer_Background.png');
        this.AddResource('white', 'Computer_Background_BrightScreen.png');
        this.AddResource('email', 'Email_Vorlage.png');
        this.AddResource('back', 'PostIt_PfeilLinks.png');
        this.AddResource('back_hover', 'PostIt_PfeilLinks_hover.png');
        this.AddResource('back_click', 'PostIt_PfeilLinks_Click.png');
        this.MENU.LoadResources();
        this.DESKTOPSCREEN.LoadResources();
        this.EMAILSCREEN.LoadResources();
        this.WEBSCREEN.LoadResources();
    }

    openEmails(){
        this.DESKTOPSCREENESCENE.visible = false;
        this.DESKTOPSCREENESCENE.interactive = false;
        this.EMAILSCREENSCENE.visible = true;
        this.EMAILSCREENSCENE.interactive = true;
        this.WEBSCREENSCENE.visible = false;
        this.WEBSCREENSCENE.interactive = false;
        this.BACKBUTTON.interactive = true;
        this.BACKBUTTON.visible = true;
        HSceneManager.INSTANCE.animate();
    }
}
