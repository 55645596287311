import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { PSideNavigation } from '../pixielements/psidenavigation';
import { PRequestScreen } from '../pixielements/prequestscreen';
import { MatDialog } from '@angular/material/dialog';
import { InformationlistComponent } from '../informationlist/informationlist.component';
import { PGameState } from './../piximodels/pgamestate';
import { PInformationScreen } from './../pixielements/pinformationscreen';
import { HSceneManager } from '../helpers/h-scenemanager';
import { EScene } from '../enums/e-scene';
import { ConfirmPointsDialogComponent } from '../confirm-points-dialog/confirm-points-dialog.component';
import { LibrarylistComponent } from '../librarylist/librarylist.component';

export class FilefolderScreen extends HScene implements IScene {
    public static INSTANCE: IScene = null;
    public ACA_BUTTON = null;
    public FIU_BUTTON = null;
    public CO_BUTTON = null;
    public AS_BUTTON = null;
    ASSET_FOLDER = this.ASSET_FOLDER + 'NahansichtenPNGs/Files';
    PREFIX = 'Files_';
    MENU = new PSideNavigation(1,this);
    FI_REQUESTSCREEN: PRequestScreen;
    ACA_REQUESTSCREEN: PRequestScreen;
    AS_REQUESTSCREEN: PRequestScreen;

    FI_RequestView = null;
    ACA_RequestView = null;
    AS_RequestView = null;
    dialog: MatDialog;

    INFOSCREEN: PInformationScreen;
    InfoView = null;

    constructor(dialog: MatDialog){
        super();
        this.dialog = dialog;
        this.FI_REQUESTSCREEN = new PRequestScreen(this.dialog,1);
        this.ACA_REQUESTSCREEN = new PRequestScreen(this.dialog,2);
        this.AS_REQUESTSCREEN = new PRequestScreen(this.dialog,3);
        this.INFOSCREEN = new PInformationScreen(this.dialog);
    }

    UpdateScene(): void {
        if (this.ACA_BUTTON){
            this.ACA_BUTTON.visible = PGameState.character == 0;
        }
        if (this.FIU_BUTTON){
            this.FIU_BUTTON.visible = PGameState.character == 1;
        }
        if (this.CO_BUTTON){
            this.CO_BUTTON.visible = PGameState.character == 2;
        }
        if (this.AS_BUTTON){
            this.AS_BUTTON.visible = PGameState.character == 2;
        }
    }

    openDialog() {
        this.dialog.open(InformationlistComponent);
    }

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const template = this.LoadSprite('template',0,0);
        const background = this.LoadSprite('background',0,0);

        // aca Button
        this.ACA_BUTTON = new PButton(
            this.LoadTexture('aca'),
            this.LoadTexture('aca_hover'),
            this.LoadTexture('aca_click'),
            558, 450
        );
        this.ACA_BUTTON.on('click', (event) => {
            this.ACA_RequestView.visible = true;
            this.ACA_REQUESTSCREEN.ResetData();
            this.ACA_REQUESTSCREEN.UpdateScene();
            HSceneManager.INSTANCE.animate();
            if (PGameState.level == 1 && !PGameState.aca_seen){
                HSceneManager.INSTANCE.showMessage("<b>Request for first ACA Investigation!</b><br><br>With this form, you can perform an Anti-Corruption Agency Request to investigate on a certain suspect.<br><br>Click on <i>- Select your investigation target -</i> to define a <b>Target</b> you want to request an ACA Investigation for.<br>Requesting investigations costs <b>Steps</b> based on the Investigation Level and wether or not it is an international request.<br><b>International Requests</b> might give you additional evidence compared to a normal investigation.<br>By clicking on the <b>Investigation Level</b> you can change it. A higher investigation level will grant you evidence of a higher level, while also requiring evidence of a higher level:<ul><li>A Level 0 Investigation does not require any evidence.</li><li>A Level 1 Investigation requires a certain minimum of Level 0 Evidence.</li><li>A Level 2 Investigation requires a certain minimum of Level 1 Evidence.</li></ul>The amount of required evidence might vary based on your investigation target.<br><br>By clicking on the <i>Plus</i>-Icon, you can attach available evidence to your request to define an assertion for your request!");
                PGameState.aca_seen = true;
            }
        });

        // fi Button
        this.FIU_BUTTON = new PButton(
            this.LoadTexture('fi'),
            this.LoadTexture('fi_hover'),
            this.LoadTexture('fi_click'),
            1152, 325
        );
        this.FIU_BUTTON.on('click', (event) => {
            this.FI_RequestView.visible = true;
            this.FI_REQUESTSCREEN.ResetData();
            this.FI_REQUESTSCREEN.UpdateScene();
            HSceneManager.INSTANCE.animate();
            if (PGameState.level == 1 && !PGameState.fi_seen){
                HSceneManager.INSTANCE.showMessage("<b>Request for first Financial Investigation!</b><br><br>With this form, you can request an Financial Investigation on an account of your choice.<br><br>Click on <i>- Select your investigation target -</i> to define a <b>Target</b> you want to request a Financial Investigation on.<br>Requesting investigations costs <b>Steps</b> based wether or not it is an international request.<br><b>International Requests</b> might give you additional evidence compared to a normal investigation.<br>The <b>Investigation Level</b> of Financial Investigations is always set to 2 and cannot be changed:<ul><li>A Level 2 Investigation requires a certain minimum of Level 1 Evidence.</li></ul>The amount of required evidence might vary based on your investigation target.<br><br>By clicking on the <i>Plus</i>-Icon, you can attach available evidence to your request to define an assertion for your request!");
                PGameState.fi_seen = true;
            }
        });


        // co Button
        this.CO_BUTTON = new PButton(
            this.LoadTexture('co'),
            this.LoadTexture('co_hover'),
            this.LoadTexture('co_click'),
            700, 252
        );
        this.CO_BUTTON.on('click', (event) => {
            let confirmPoints = this.dialog.open(ConfirmPointsDialogComponent);
            let pointCosts = 10;
            confirmPoints.componentInstance.setData(pointCosts,this.dialog);
            confirmPoints.componentInstance.CALLBACK = () => {
                HSceneManager.INSTANCE.changeSceneById(EScene.SCORESCREEN); 
                HSceneManager.INSTANCE.animate();
            };  
            if (PGameState.level == 1 && !PGameState.courtorder_seen){
                HSceneManager.INSTANCE.showMessage("<b>Court Order Request!</b><br><br>A Court order request will rate your overall performance and is considered the final step of your investigation (and therefore costing a lot of <b>Steps</b> in the process). Only do this step, when you feel confident that you have unlocked all available evidences or are out of steps.");
                PGameState.courtorder_seen = true;
            }  
        });

        // as Button
        this.AS_BUTTON = new PButton(
            this.LoadTexture('as'),
            this.LoadTexture('as_hover'),
            this.LoadTexture('as_click'),
            1320, 332
        );
        this.AS_BUTTON.on('click', (event) => {
            this.AS_RequestView.visible = true;
            this.AS_REQUESTSCREEN.ResetData();
            this.AS_REQUESTSCREEN.UpdateScene();
            HSceneManager.INSTANCE.animate();
            if (PGameState.level == 2 && !PGameState.as_seen){
                HSceneManager.INSTANCE.showMessage("<b>Request asset seizure!</b><br><br>With this form, you can request an Asset Seizure for an asset of your choice.<br><br>Click on <i>- Select your asset of choice -</i> to define the <b>Asset</b> you want to seize.<br><br><b>International Requests</b> might be required for certain assets, which are not based in ALKONIA.<br><br>You need to create an assertion involving your asset of choice to give a valid explanation for your request.");
                PGameState.as_seen = true;
            }
        });

        
        // info Button
        const info = new PButton(
            this.LoadTexture('info'),
            this.LoadTexture('info_hover'),
            this.LoadTexture('info_click'),
            1300, 587
        );
        info.on('click', (event) => {
            this.InfoView.visible = !this.InfoView.visible;
            HSceneManager.INSTANCE.animate();
            if (PGameState.level == 1 && !PGameState.evidence_seen){
                HSceneManager.INSTANCE.showMessage("<b>This is a collection of your collected evidence!</b><br><br>This is an overview of all of your unlocked evidence in the game.<br><br>Evidence can be filtered by:<ul><li>Investigation Target / Suspect</li><li>Evidence Type</li><li>Evidence Quality Level</li></ul>Evidence is represented in <b>Cards</b>, which contain descriptions aswell as informations about their quality level, type and involved suspects for a visual representation.<br><br>Evidence Cards can be used in Investigation Requests as Attachments to create an assertion for its foundation.");
                PGameState.evidence_seen = true;
            }
        });

        // info Button
        const pdf = new PButton(
            this.LoadTexture('library'),
            this.LoadTexture('library_hover'),
            this.LoadTexture('library_click'),
            640, 88
        );
        pdf.on('click', (event) => {
            this.dialog.open(LibrarylistComponent);
            if (PGameState.level == 1 && !PGameState.library_seen){
                HSceneManager.INSTANCE.showMessage("<b>This is your library!</b><br><br>Here you can access additional literature regarding money laundering. Opening a document will open it in a new browser window!");
                PGameState.library_seen = true;
            }
        });

        this.FI_REQUESTSCREEN.ConnectFallback(() => {
            this.FI_RequestView.visible = !this.FI_RequestView.visible;
            HSceneManager.INSTANCE.animate();
        });

        this.ACA_REQUESTSCREEN.ConnectFallback(() => {
            this.ACA_RequestView.visible = !this.ACA_RequestView.visible;
            HSceneManager.INSTANCE.animate();
        });

        this.AS_REQUESTSCREEN.ConnectFallback(() => {
            this.AS_RequestView.visible = !this.AS_RequestView.visible;
            HSceneManager.INSTANCE.animate();
        });

        this.FI_RequestView = this.FI_REQUESTSCREEN.BuildScene();
        this.FI_RequestView.interactive = true;
        this.FI_RequestView.visible = false;

        this.ACA_RequestView = this.ACA_REQUESTSCREEN.BuildScene();
        this.ACA_RequestView.interactive = true;
        this.ACA_RequestView.visible = false;

        this.AS_RequestView = this.AS_REQUESTSCREEN.BuildScene();
        this.AS_RequestView.interactive = true;
        this.AS_RequestView.visible = false;

        this.INFOSCREEN.ConnectFallback(null,() => {
            this.InfoView.visible = !this.InfoView.visible;
            HSceneManager.INSTANCE.animate();
        });
        this.InfoView = this.INFOSCREEN.BuildScene();
        this.InfoView.interactive = true;
        this.InfoView.visible = false;

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(this.ACA_BUTTON);
        container.addChild(this.FIU_BUTTON);
        container.addChild(this.CO_BUTTON);
        if (PGameState.level >= 2){
            container.addChild(this.AS_BUTTON);
        }
        container.addChild(info);
        container.addChild(pdf);
        container.addChild(this.MENU.BuildScene());
        container.addChild(this.FI_RequestView);
        container.addChild(this.ACA_RequestView);
        container.addChild(this.AS_RequestView);
        container.addChild(this.InfoView);
        this.UpdateScene();
        return container;
    } 

    LoadResources(): void {
        this.AddResource('template', 'FileView_Vorlage.png');
        this.AddResource('background', 'FilesBackground.png');
        this.AddResource('aca', 'FileLabel_ACAInvestigation.png');
        this.AddResource('aca_click', 'FileLabel_ACAInvestigation_Click.png');
        this.AddResource('aca_hover', 'FileLabel_ACAInvestigation_Hover.png');
        this.AddResource('co', 'FileLabel_CourtOrderRequest.png');
        this.AddResource('co_click', 'FileLabel_CourtOrderRequest_Click.png');
        this.AddResource('co_hover', 'FileLabel_CourtOrderRequest_Hover.png');
        this.AddResource('fi', 'FileLabel_FinancialInvestigationRequest.png');
        this.AddResource('fi_click', 'FileLabel_FinancialInvestigationRequest_Click.png');
        this.AddResource('fi_hover', 'FileLabel_FinancialInvestigationRequest_Hover.png');
        this.AddResource('as', 'RequestAssetSeizure.png');
        this.AddResource('as_click', 'RequestAssetSeizure_Click.png');
        this.AddResource('as_hover', 'RequestAssetSeizure_Hover.png');
        this.AddResource('info', 'FileLabel_Evidence.png');
        this.AddResource('info_click', 'FileLabel_Evidence_Click.png');
        this.AddResource('info_hover', 'FileLabel_Evidence_Hover.png');
        this.AddResource('library', 'FileLabel_Library.png');
        this.AddResource('library_click', 'FileLabel_Library_Click.png');
        this.AddResource('library_hover', 'FileLabel_Library_Hover.png');
        this.MENU.LoadResources();
        this.FI_REQUESTSCREEN.LoadResources();
        this.ACA_REQUESTSCREEN.LoadResources();
        this.AS_REQUESTSCREEN.LoadResources();
        this.INFOSCREEN.LoadResources();
    }
}
