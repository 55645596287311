import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dead-end-dialog-message',
  templateUrl: './dead-end-dialog-message.component.html',
  styleUrls: ['./dead-end-dialog-message.component.css']
})
export class DeadEndDialogMessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
