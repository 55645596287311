import * as PIXI from 'pixi.js-legacy';

export class HScene {
    ASSET_FOLDER = './../../assets/';
    PREFIX = '';

    AddResource(key: string, file: string): void {
        PIXI.Loader.shared.add(this.PREFIX + key, this.ASSET_FOLDER + '/' + file);
    }

    LoadSprite(key: string, x: number, y:number, enabled?:boolean): PIXI.Sprite {
        const sprite = new PIXI.Sprite(this.LoadTexture(key));
        sprite.x = x;
        sprite.y = y;
        if (enabled != null){
            sprite.visible = enabled;
        }
        return sprite;
    }

    LoadText(stringText: string, x: number, y: number): PIXI.Text {
        const text = new PIXI.Text(stringText);
        text.x = x;
        text.y = y;
        return text;
    }

    LoadTexture(key: string): PIXI.Texture {
        return PIXI.Texture.from(this.PREFIX + key);
    }
}
