import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-librarylist',
  templateUrl: './librarylist.component.html',
  styleUrls: ['./librarylist.component.css']
})
export class LibrarylistComponent implements OnInit {

  pdfs = [
    {"Name":"Operational Issues - Financial Investigation Guidance","File":"FATF_Operational Issues_Financial Investigations Guidance.pdf","PDF":1},
    {"Name":"Fighting Tax Crime - The Ten Global Principles, Second Edition","File":"OECD_Fighting Tax Crime-The Ten Global Principles.pdf","PDF":1},
    {"Name":"Bribery and Corruption Awareness Handbook for Tax Examiners and Tax Auditors","File":"OECD_Bribery and Corruption Awareness Handbook for Tax Examiners and Tax Auditors.pdf","PDF":1},
    {"Name":"Asset Recovery Handbook, A Guide for Practitioners","File":"StAR_Asset Recovery Handbook-A Guide for Practitioners.pdf","PDF":1},
    {"Name":"Tracing Illegal Assets - A Practitioner's Guide","File":"ICAR_Tracing Illegal Assets.pdf","PDF":1},
    {"Name":"International Cooperation and Mutual Legal Assistance in Criminal Matters","File":"https://www.baselgovernance.org","PDF":0},
    {"Name":"Model Request Form for Mutual Assistance in Criminal Matters","File":"https://www.coe.int/en/web/transnational-criminal-justice-pcoc/model-request-form-for-mutual-assistance-in-criminal-matters","PDF":0},
    {"Name":"The Role of FIUs in fighting corruption and asset recovery","File":"EGMONT Group_The Role of Financial Intelligence Units in Fighting Corruption and Asset Recovery.pdf","PDF":1},
    {"Name":"Understanding FIU operational independence and autonomy","File":"EGMONT Group_Understanding FIU Operational Independence and Autonomy.pdf","PDF":1},
    {"Name":"Financial Intelligence Units working with law enforcement authorities and prosecutors","File":"StAR-UNODC-EGMONT Group_Financial Intelligence Units working with Law Enforcement Authorities and Prosecutors.pdf","PDF":1},
    {"Name":"Customs - FIU Cooperation Handbook","File":"EGMONT Group-WCO_CUSTOMS-FIU Cooperation Handbook.pdf","PDF":1},
    {"Name":"Money Laundering and Terrorist Financing Awareness Handbook for Tax Examiners and Tax Auditors","File":"OECD_Money Laundering and Terrorist Financing Awareness Handbook for Tax Examiners and Tax Auditors.pdf","PDF":1},
    {"Name":"Money Laundering through the Physical Transportation of Cash","File":"FATF_Money Laundering through the Physical Transportation of Cash.pdf","PDF":1},
    {"Name":"Professional Money Laundering","File":"FATF_Professional Money Laundering.pdf","PDF":1},
    {"Name":"Egmont Group Bulletin Professional Money Laundering Facilitators","File":"EGMONT Group_Bulletin_Professional Money Laundering Facilitators.pdf","PDF":1},
    {"Name":"FATF Terrorist Financing Typologies Report","File":"FATF_Terrorist Financing Typologies Report.pdf","PDF":1},
    {"Name":"Typologies Report on Proliferation Financing","File":"FATF_Guidance on Proliferation Financing Risk Assessment and Mitigation.pdf","PDF":1},
    {"Name":"Risk of terrorist abuse in non-profit organisations","File":"FATF_Risk of Terrorist Abuse in Non-Profit Organisations.pdf","PDF":1},
    {"Name":"Public Summary: Counter Terrorist Financing Project - Lone Actors and Small Cells","File":"EGMONT Group_Counter Terrorist Financing Project.pdf","PDF":1},
    {"Name":"Guidance for a Risk-Based Approach to Virtual Assets and Virtual Asset Service Providers","File":"FATF_Virtual Assets and Virtual Asset Service Providers.pdf","PDF":1},
    {"Name":"Concealment of Beneficial Ownership","File":"FATF-EGMONT Group_Concealment of Beneficial Ownership.pdf","PDF":1},
    {"Name":"A Beneficial Ownership Implementation Toolkit","File":"OECD-IDB_A Beneficial Ownership Implementation Toolkit.pdf","PDF":1},
    {"Name":"FATF Guidance: Politically Exposed Persons (Rec. 12 and 22)","File":"FATF_Guidance_Politically Exposed Persons-Rec12-22.pdf","PDF":1},
    {"Name":"Artificial intelligence and machine learning in financial services","File":"FSB_Artificial Intelligence and Machine Learning in Financial Services.pdf","PDF":1},
    {"Name":"Stocktake on Data Pooling, Collaborative Analytics and Data Protection","File":"FATF_Stocktake on Data Pooling.Collaborative Analytics and Data Protection.pdf","PDF":1},
    {"Name":"Tax Crime Investigation Maturity Model","File":"OECD_Tax Crime Investigation Maturity Model.pdf","PDF":1},
    {"Name":"COVID-19 Best Practices Public Summary","File":"EGMONT-ECOFEL_COVID-19 Best Practices for Financial Intelligence Units.pdf","PDF":1}
    ];
  constructor() { }

  ngOnInit(): void {
  }

  openpdf(pdf:any):void{
    if (pdf.PDF == 1){
      window.open("./assets/PDFs/" + pdf.File);
    } else {
      window.open(pdf.File)
    }
  }

}
