import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PGameState } from '../piximodels/pgamestate';

@Component({
  selector: 'app-filter-selection-suspect',
  templateUrl: './filter-selection-suspect.component.html',
  styleUrls: ['./filter-selection-suspect.component.css']
})
export class FilterSelectionSuspectComponent implements OnInit {

  filterSuspects = [
    {name: 'All', value: -1}
  ];
  CALLBACK: (name,value) => void = null;

  dialog: MatDialog;
  constructor(dialog: MatDialog) {
    this.dialog = dialog;
    this.loadData();
  }

  ngOnInit(): void {
  }

  close(info: any){
    this.CALLBACK(info.name,info.value);
    this.dialog.closeAll();
  }

  loadData(): void {
    this.filterSuspects = [
      {name: 'All', value: -1}
    ];

    for (let i=0; i<PGameState.unlockedTargets.length; i++){
      let target = PGameState.allTargets[PGameState.unlockedTargets[i]];
      this.filterSuspects.push({name: target.name, value: target.id});
    }
  }

}
