import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-informationsingle',
  templateUrl: './informationsingle.component.html',
  styleUrls: ['./informationsingle.component.css']
})
export class InformationsingleComponent implements OnInit {
  info = {name: 'Mr.Eddy', info: 'Test 123', pic: null, hint: null}

  constructor() { }

  ngOnInit(): void {
  }

}
