import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PDragContainer } from '../pixielements/pdragcontainer';

import * as PIXI from 'pixi.js-legacy';
import { PSideNavigation } from '../pixielements/psidenavigation';
import { PButton } from '../pixielements/pbutton';
import { NyiComponent } from '../nyi/nyi.component';
import { MatDialog } from '@angular/material/dialog';
import { PGameState } from './../piximodels/pgamestate';
import { HostListener } from '@angular/core';
import { HSceneManager } from '../helpers/h-scenemanager';

export class WhiteboardScreen extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'NahansichtenPNGs/Whiteboard';
    PREFIX = 'WhiteboardScreen_';
    MENU = new PSideNavigation(4,this);

    dragging = false;
    data = null;

    courtOrderRequest = null;
    dialog: MatDialog;
    
    addedLayers: string[];
    progressContainer = null;

    constructor(dialog: MatDialog){
        super();
        this.dialog = dialog;
    }
    
    UpdateScene(): void {
        if (this.courtOrderRequest){
            this.courtOrderRequest.visible = PGameState.character == 2;
        }
    }

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();
        this.progressContainer = new PDragContainer();
        this.progressContainer.width = 10000;
        this.progressContainer.height = 10000;

        // Background
        const background = this.LoadSprite('background',960,540);
        background.anchor.set(0.5,0.5);
        background.scale.set(1.75,1.25);

        // Add Elements to Canvas
        container.addChild(background);

        this.courtOrderRequest = new PButton(
            this.LoadTexture('CourtOrderRequest'),
            this.LoadTexture('CourtOrderRequest_Hover'),
            this.LoadTexture('CourtOrderRequest_Click'),
            960, 942
        );
        this.courtOrderRequest.anchor.set(0.5,0.5);
        this.courtOrderRequest.visible = PGameState.character == 2;

        this.courtOrderRequest.on('click', (event) => {
            this.dialog.open(NyiComponent);
        });

        this.addedLayers = [];
        for (let i=0; i<PGameState.unlockedCards.length;i++){
            if (!this.addedLayers.includes(PGameState.allCards[PGameState.unlockedCards[i]].unlockWhiteboard[0])) {
                this.addedLayers.push(PGameState.allCards[PGameState.unlockedCards[i]].unlockWhiteboard[0])
            }
        }

        /*let bg = this.LoadSprite('BG',960,540);
        bg.scale.set(0.75,0.75);
        bg.anchor.set(0.5,0.5);
        container.addChild(bg);*/

        for (let i=0; i<this.addedLayers.length;i++){
            let layer = this.LoadSprite(this.addedLayers[i],960,540);
            layer.anchor.set(0.5,0.5);
            this.progressContainer.addChild(layer);
        }

        container.addChild(this.progressContainer);
        //container.addChild(this.courtOrderRequest);
        container.addChild(this.MENU.BuildScene());
        return container;
    }

    UnlockableResource(id: string): void {
        this.AddResource(id, 'Unlocks/' + id + '.png');
    }

    LoadResources(): void {
        this.AddResource('background', 'Whiteboard.png');

        this.UnlockableResource('-1');
        this.UnlockableResource('1');
        this.UnlockableResource('2');
        this.UnlockableResource('3');
        this.UnlockableResource('4');
        this.UnlockableResource('6');
        this.UnlockableResource('10');
        this.UnlockableResource('12');
        this.UnlockableResource('13');
        this.UnlockableResource('14');
        this.UnlockableResource('15');
        this.UnlockableResource('19');
        this.UnlockableResource('20');
        this.UnlockableResource('22_1');
        this.UnlockableResource('23');
        this.UnlockableResource('23_1');
        this.UnlockableResource('24');
        this.UnlockableResource('24_1');
        this.UnlockableResource('25');
        this.UnlockableResource('25_1');
        this.UnlockableResource('26');
        this.UnlockableResource('27');
        this.UnlockableResource('29');
        this.UnlockableResource('30');
        this.UnlockableResource('31');
        this.UnlockableResource('35');
        this.UnlockableResource('36');
        this.UnlockableResource('40');
        this.UnlockableResource('42');
        this.UnlockableResource('50');
        this.UnlockableResource('51');
        this.UnlockableResource('56');
        this.UnlockableResource('67');
        this.UnlockableResource('72.1');
        this.UnlockableResource('72.2');
        this.UnlockableResource('72.3');
        this.UnlockableResource('72.4');
        this.UnlockableResource('72.6');
        this.UnlockableResource('72');
        this.UnlockableResource('74.1');
        this.UnlockableResource('74.2');
        this.UnlockableResource('74.3');
        this.UnlockableResource('74.4');
        this.UnlockableResource('74.5');
        this.UnlockableResource('74');
        this.UnlockableResource('77.1');
        this.UnlockableResource('77.2');
        this.UnlockableResource('77');
        this.UnlockableResource('78.1');
        this.UnlockableResource('78');
        this.UnlockableResource('80.1');
        this.UnlockableResource('80.2');
        this.UnlockableResource('80.3');
        this.UnlockableResource('80.4');
        this.UnlockableResource('80');
        this.UnlockableResource('90');
        this.UnlockableResource('97');
        this.UnlockableResource('99');
        this.UnlockableResource('108');
        this.UnlockableResource('111');
        this.UnlockableResource('114');
        this.UnlockableResource('117');
        this.UnlockableResource('118');
        this.UnlockableResource('119');
        this.UnlockableResource('120');
        this.UnlockableResource('121');
        this.UnlockableResource('124');
        this.UnlockableResource('135');
        this.UnlockableResource('139');
        this.UnlockableResource('151');
        this.UnlockableResource('153');
        this.UnlockableResource('154');
        this.UnlockableResource('158');
        this.UnlockableResource('165');
        this.UnlockableResource('169');
        this.UnlockableResource('171');
        this.UnlockableResource('173');
        this.UnlockableResource('180');
        this.UnlockableResource('184');
        this.UnlockableResource('186');
        this.UnlockableResource('187');
        this.UnlockableResource('190');
        this.UnlockableResource('191');
        this.UnlockableResource('195');
        this.UnlockableResource('198');
        this.UnlockableResource('199');
        this.UnlockableResource('205');
        this.UnlockableResource('206');
        this.UnlockableResource('207');
        this.UnlockableResource('208');
        this.UnlockableResource('209');
        this.UnlockableResource('210');
        this.UnlockableResource('211');
        this.UnlockableResource('218');
        this.UnlockableResource('220');
        this.UnlockableResource('221');
        this.UnlockableResource('222');
        this.UnlockableResource('223');
        this.UnlockableResource('224');
        this.UnlockableResource('227');
        this.UnlockableResource('243');
        this.UnlockableResource('264');
        this.UnlockableResource('265');
        this.UnlockableResource('BG');

        this.UnlockableResource('t0');
        this.UnlockableResource('t1');
        this.UnlockableResource('t2');
        this.UnlockableResource('t4');
        this.UnlockableResource('t5');
        this.UnlockableResource('t6');
        this.UnlockableResource('t7');
        this.UnlockableResource('t8');
        this.UnlockableResource('t13');
        this.UnlockableResource('t14');
        this.UnlockableResource('t16');
        this.UnlockableResource('t17');
        this.UnlockableResource('t21');

        this.UnlockableResource('2___1');
        this.UnlockableResource('2___2');
        this.UnlockableResource('2___3');
        this.UnlockableResource('2___4');
        this.UnlockableResource('2___5');
        this.UnlockableResource('2___6');
        this.UnlockableResource('2___7');
        this.UnlockableResource('2___8');
        this.UnlockableResource('2___9');
        this.UnlockableResource('2___10');
        this.UnlockableResource('2___11');
        this.UnlockableResource('2___12');
        this.UnlockableResource('2___13');
        
        this.AddResource('CourtOrderRequest', 'CourtOrderRequest.png');
        this.AddResource('CourtOrderRequest_Hover', 'CourtOrderRequest_Hover.png');
        this.AddResource('CourtOrderRequest_Click', 'CourtOrderRequest_Click.png');
        this.MENU.LoadResources();

        document.addEventListener("mousewheel", (event: WheelEvent) => {
            if (this.progressContainer != null){
                try {
                    this.progressContainer.scale.set(this.progressContainer.scale.x-event.deltaY/1000,this.progressContainer.scale.x-event.deltaY/1000);
                    HSceneManager.INSTANCE.animate();
                } catch (e){}
            }
        }, false);
    }
}
