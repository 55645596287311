import * as PIXI from 'pixi.js-legacy';
import { HScene } from '../helpers/h-scene';
import { IScene } from '../interfaces/i-scene';
import { PGameState } from '../piximodels/pgamestate';
import { PButton } from './pbutton';

export class PHintScreen extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/HintScreen_PNGs';
    PREFIX = 'HintScreen';
    CALLBACK: () => void = null;

    constructor(){
        super();
    }
    
    UpdateScene(): void {}

    ConnectFallback(connectFeedback: () => void): void {
        this.CALLBACK = connectFeedback;
    }

    BuildScene(): PIXI.Container {
        const view = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        const klemm = this.LoadSprite('klemm',621,111);
        const moderator = this.LoadSprite('moderator',1340,660);
        moderator.anchor.set(0.5,0.5);

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            960, 825
        );
        back.anchor.set(0.5,0.5);
        back.on('click', (event) => {
            this.CALLBACK();
        });

        const description = this.LoadText(this.GetBriefingText(), 100, 245);
        description.style = this.GetBriefingTextStyle();
        const header = this.LoadText(this.GetBriefingHeader(), 100, 175);
        header.style = this.GetBriefingHeaderStyle();

        view.addChild(background);
        view.addChild(klemm);
        klemm.addChild(description);
        klemm.addChild(header);
        view.addChild(moderator);
        view.addChild(back);

        return view;
    }

    LoadResources(): void {
        if (PHintScreen.loaded){
            return;
        }
        this.AddResource('background', 'HintScreen_Background.png');
        this.AddResource('klemm', 'HintScreen_Klemmbrett.png');
        this.AddResource('back', 'HintScreen_BackButton.png');
        this.AddResource('back_hover', 'HintScreen_BackButton_Hover.png');
        this.AddResource('back_click', 'HintScreen_BackButton_Click.png');
        this.AddResource('moderator', 'HintScreen_Moderator.png');
        PHintScreen.loaded = true;
    }

    
    GetBriefingText(): string {
        let unlocked = PGameState.unlockedCards.length/PGameState.allCards.length;

        if (PGameState.level == 1){
            if (unlocked > 0.1 && unlocked <= 0.5){ 
                return 'Great! You figured out how to start an investigation. But if we can find more suspects and higher quality investigations going.';
            }
            if (unlocked > 0.5 && unlocked <= 0.7){ 
                return 'Good! You already discovered a lot about this case. Maybe try to focus on how owners and CEOs of those companies are connected.';
            }
            if (unlocked > 0.7 && unlocked <= 0.9){ 
                return 'Great! You almost discovered everything there is to find. But it seems like something is still missing';
            }
            if (unlocked > 0.9){ 
                return 'Super! It seems like you have a very solid case to make a court order request.';
            }
        }
        return 'Check your available evidence using your file folder to get some general information about your case.';
    }

    GetBriefingHeader(): string {
        return 'Hint Information';
    }

    GetBriefingTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontSize: 26
        });
    }

    GetBriefingHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }
}
