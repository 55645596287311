import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { PGameState } from '../piximodels/pgamestate';

export class ComputerDesktopScreen extends HScene implements IScene {
    UpdateScene(): void {}
    
    ASSET_FOLDER = this.ASSET_FOLDER + 'Desktop';
    PREFIX = 'ComputerDesktopScreen_';
    MAILCALLBACK: () => void = null;
    WEBCALLBACK: () => void = null;

    ConnectFallback(mailCallback: () => void, webCallback: () => void): void {
        this.MAILCALLBACK = mailCallback;
        this.WEBCALLBACK = webCallback;
    }

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',431,121);

        const mail = new PButton(
            this.LoadTexture('mail'),
            this.LoadTexture('mail_hover'),
            this.LoadTexture('mail_click'),
            460, 325
        );
        mail.on('click', (event) => {
            this.MAILCALLBACK();
            HSceneManager.INSTANCE.animate();
        });

        const web = new PButton(
            this.LoadTexture('web'),
            this.LoadTexture('web_hover'),
            this.LoadTexture('web_click'),
            460, 140
        );
        web.on('click', (event) => {
            this.WEBCALLBACK();
            HSceneManager.INSTANCE.animate();
        });

        const feedback = new PButton(
            this.LoadTexture('feedback'),
            this.LoadTexture('feedback_hover'),
            this.LoadTexture('feedback_click'),
            550, 785
        );
        feedback.anchor.set(0.5,0.5);
        feedback.on('click', (event) => {
            window.open("mailto:m.hoelzenbein@quantumfrog.de?subject=Follow the Money - Feedback");
            HSceneManager.INSTANCE.animate();
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(web);
        container.addChild(mail);
        container.addChild(feedback);
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'Desktop_Background.png');
        this.AddResource('mail', 'Mail_Button.png');
        this.AddResource('mail_click', 'Mail_Button_Click.png');
        this.AddResource('mail_hover', 'Mail_Button_Hover.png');
        this.AddResource('web', 'Web_Button.png');
        this.AddResource('web_click', 'Web_Button_Click.png');
        this.AddResource('web_hover', 'Web_Button_Hover.png');
        this.AddResource('feedback', 'Feedback.png');
        this.AddResource('feedback_click', 'Feedback_Click.png');
        this.AddResource('feedback_hover', 'FeedbackHover.png');
    }
}
