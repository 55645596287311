import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from './helpers/h-scenemanager';
import { StartMenu } from './pixiscenes/startmenu';
import { MainMenu } from './pixiscenes/mainmenu';
import { NewGameScreen } from './pixiscenes/newgamescreen';
import { EScene } from './enums/e-scene';
import { BriefingScreen } from './pixiscenes/briefingscreen';
import { OfficeScreen } from './pixiscenes/officescreen';
import { ComputerScreen } from './pixiscenes/computerscreen';
import { FilefolderScreen } from './pixiscenes/filefolderscreen';
import { TelephoneScreen } from './pixiscenes/telephonescreen';
import { WhiteboardScreen } from './pixiscenes/whiteboardscreen';
import { SettingsScreen } from './pixiscenes/settingsscreen';
import { LoadGameScreen } from './pixiscenes/loadgamescreen';
import { ScoreScreen } from './pixiscenes/scorescreen';
import { LoadScreen } from './pixiscenes/loadscreen';
import { MatDialog } from '@angular/material/dialog';

window.PIXI = PIXI;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'ftm-app';

  constructor(public dialog : MatDialog){}

  @ViewChild('pixiEngine') engine;
  public pixiApp: PIXI.Application;

  ngOnInit(): void {
    PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

    this.pixiApp = new PIXI.Application({
      width: 1920,
      height: 1080,
      backgroundColor: 0x000000,
      sharedTicker: true,
      sharedLoader: true
    });

    // Center the Pixi Container. Does not work using CSS
    this.pixiApp.view.style.margin = '0 auto';
    this.pixiApp.view.style.display = 'block';
    PIXI.Ticker.shared.autoStart = false;
    PIXI.Ticker.shared.stop();
    this.updateSize();
  }

  // Adjust Container Size based on Window Size
  updateSize(): void {
    const height = window.innerHeight;
    const width = window.innerWidth;
    const ratio = width / height;

    if (ratio >= ( 16 / 9 ) )
    {
      this.pixiApp.view.style.height = window.innerHeight + 'px';
      this.pixiApp.view.style.width = '';
    } else {
      this.pixiApp.view.style.height = '';
      this.pixiApp.view.style.width = window.innerWidth + 'px';
    }
  }

  // Adjust Container Size when Resizing
  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.updateSize();
  }

  ngAfterViewInit(): void {
    this.engine.nativeElement.appendChild(this.pixiApp.view);
    this.loadScreen();
  }

  loadScreen(): void {
    const loadingscreen = new LoadScreen();
    loadingscreen.LoadResources();
    PIXI.Loader.shared.load(() => {
      this.pixiApp.stage = loadingscreen.BuildScene();
      this.loadScenes();
    });
  }

  loadScenes(): void {
    const sceneManager = new HSceneManager(this.pixiApp,this.dialog);
    HSceneManager.INSTANCE.animate();
    sceneManager.addScene(new StartMenu(),EScene.STARTMENU);
    sceneManager.addScene(new MainMenu(this.dialog),EScene.MAINMENU);
    sceneManager.addScene(new NewGameScreen(this.dialog),EScene.NEWGAMESCREEN);
    sceneManager.addScene(new BriefingScreen(),EScene.BRIEFINGSCREEN);
    sceneManager.addScene(new OfficeScreen(),EScene.OFFICESCREEN);
    sceneManager.addScene(new ComputerScreen(),EScene.COMPUTERSCREEN);
    sceneManager.addScene(new FilefolderScreen(this.dialog),EScene.FILEFOLDERSCREEN);
    sceneManager.addScene(new TelephoneScreen(),EScene.TELEPHONESCREEN);
    sceneManager.addScene(new WhiteboardScreen(this.dialog),EScene.WHITEBOARDSCREEN);
    sceneManager.addScene(new SettingsScreen(), EScene.SETTINGSSCREEN);
    sceneManager.addScene(new LoadGameScreen(this.dialog), EScene.LOADGAMESCREEN);
    sceneManager.addScene(new ScoreScreen(), EScene.SCORESCREEN);
    sceneManager.addScene(new LoadScreen(),EScene.LOADINGSCREEN);
    sceneManager.LoadAllResources();
  }

}
