import { MatDialog } from '@angular/material/dialog';
import * as PIXI from 'pixi.js-legacy';
import { ConfirmPointsDialogComponent } from '../confirm-points-dialog/confirm-points-dialog.component';
import { EScene } from '../enums/e-scene';
import { ErrorDialogMessageComponent } from '../error-dialog-message/error-dialog-message.component';
import { HScene } from '../helpers/h-scene';
import { HSceneManager } from '../helpers/h-scenemanager';
import { IScene } from '../interfaces/i-scene';
import { PGameState } from '../piximodels/pgamestate';
import { RequestlistComponent } from '../requestlist/requestlist.component';
import { PButton } from './pbutton';
import { PCard } from './pcard';
import { PInformationScreen } from './pinformationscreen';

export class PRequestScreen extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/RequestFormsPNGs';
    PREFIX = 'RequestScreen';
    CALLBACK: () => void = null;
    ITEMS: number[] = [];
    CARDS = [];
    
    dialog: MatDialog;
    investigatorTargetText: any;
    type: number;
    internationalcheck: boolean = false;
    checkedView = null;

    INFOSCREEN: PInformationScreen;
    InfoView = null;

    viewReference = null;
    level: number = 0;
    target: number = -1;

    constructor(dialog: MatDialog, type: number){
        super();
        this.dialog = dialog;
        this.type = type;
        if (this.type == 1){
            this.level = 2;
        }
        
        this.INFOSCREEN = new PInformationScreen(this.dialog);
    }

    ResetData(){
        this.internationalcheck = false;
        this.ITEMS = [];
        if (this.type == 1){
            this.level = 2;
        } else {
            this.level = 0;
        }
    }

    UpdateScene(): void {
        if (!this.internationalcheck){
            this.checkedView.texture = this.LoadTexture('uncheck');
            this.checkedView.textureNormal  = this.LoadTexture('uncheck');
        } else {
            this.checkedView.texture = this.LoadTexture('check');
            this.checkedView.textureNormal  = this.LoadTexture('check');
        }

        for (let i=0; i<this.CARDS.length; i++){
            this.viewReference.removeChild(this.CARDS[i]);
        }

        this.CARDS = [];
        for (let i=0; i<this.ITEMS.length; i++){
            let cardData = PGameState.allCards[this.ITEMS[i]];
            this.CARDS.push(new PCard(cardData));
            this.CARDS[i].anchor.set(0.5,0.5);
            this.CARDS[i].position.set(721+((i)*60),805);
            this.CARDS[i].scale.set(0.35,0.35);
            this.CARDS[i].interactive = true;
            this.viewReference.addChild(this.CARDS[i]);
        }
    }

    ConnectFallback(connectFeedback: () => void): void {
        this.CALLBACK = connectFeedback;
    }

    BuildScene(): PIXI.Container {
        this.ResetData();
        this.viewReference = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        let form: PIXI.Sprite;

        switch (this.type){
            case 1:
                form = this.LoadSprite('fi_form',960,540);
                break;
            case 2:
                form = this.LoadSprite('aca_form',960,540);
                break;
            case 3:
                form = this.LoadSprite('as_form',960,540);
                break;
            default:
                form = this.LoadSprite('fi_form',960,540);
                break;
        }
        form.anchor.set(0.5,0.5);

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            850, 985
        );
        back.anchor.set(0.5,0.5);
        back.on('click', (event) => {
            this.CALLBACK();
            HSceneManager.INSTANCE.animate();
        });

        // send Button
        const send = new PButton(
            this.LoadTexture('send'),
            this.LoadTexture('send_hover'),
            this.LoadTexture('send_click'),
            1070, 985
        );
        send.anchor.set(0.5,0.5);
        send.on('click', (event) => {
            if (this.target <= -1){
                let errorMessage = this.dialog.open(ErrorDialogMessageComponent);
                switch (this.type){
                    case 3:
                        errorMessage.componentInstance.message = "No Asset is selected";
                        break;
                    default:
                        errorMessage.componentInstance.message = "No Investigation Target is selected";
                        break;
                }
            } else {
                let confirmPoints = this.dialog.open(ConfirmPointsDialogComponent);
                let pointCosts = 1;
                if (this.level >= 2){
                    pointCosts++;
                }
                if (this.internationalcheck){
                    pointCosts++;
                }
                confirmPoints.componentInstance.setData(pointCosts,this.dialog);
                confirmPoints.componentInstance.CALLBACK = () => {
                    PGameState.checkNewCards(this.target,this.type,this.level,this.ITEMS,this.dialog);
                    this.CALLBACK();
                    HSceneManager.INSTANCE.changeSceneById(EScene.FILEFOLDERSCREEN);
                    HSceneManager.INSTANCE.animate();
                };
            }
            //this.dialog.open(NyiComponent);
            HSceneManager.INSTANCE.animate();
        });

        this.checkedView = new PButton(
            this.LoadTexture('uncheck'),
            this.LoadTexture('check'),
            this.LoadTexture('check'),
            860, 315
        );
        this.checkedView.on('click', (event) => {
            this.internationalcheck = !this.internationalcheck;
            this.UpdateScene();
            
            console.log(this.internationalcheck);
            HSceneManager.INSTANCE.animate();
        });
        this.checkedView.scale.set(0.15,0.15);

        const description = this.LoadText(this.GetBriefingText(), 933, 475);
        description.style = this.GetBriefingTextStyle();
        description.anchor.set(0.5,0.5);


        const levelText = this.LoadText("Investigation Level " + this.level, 1000,320);
        levelText.style = this.GetLinkTextStyle();
        levelText.interactive = true;
        levelText.buttonMode = true;

        if (this.type == 2){
            levelText.on('click', (event) => {
                this.level++;
                if (this.level > 2){
                    this.level = 0;
                }
                levelText.text = "Investigation Level " + this.level;
                HSceneManager.INSTANCE.animate();
            });
        }

        this.investigatorTargetText = this.LoadText(this.GetLinkText(), 933, 275);
        this.investigatorTargetText.style = this.GetLinkTextStyle();
        this.investigatorTargetText.anchor.set(0.5,0.5);
        this.investigatorTargetText.interactive = true;
        this.investigatorTargetText.buttonMode = true;
        this.investigatorTargetText.on('click', (event) => {
            let dialogRef = this.dialog.open(RequestlistComponent);
            dialogRef.componentInstance.type = this.type;
            dialogRef.componentInstance.CALLBACK = (data,value) => {
                this.investigatorTargetText.text = data;
                this.target = value;
                HSceneManager.INSTANCE.animate();
            };
            HSceneManager.INSTANCE.animate();
        });

        const addButton = new PButton(
            this.LoadTexture('add'),
            this.LoadTexture('add_click'),
            this.LoadTexture('add_click'),
            1210, 622
        );
        addButton.on('click', (event) => {
            if (this.CARDS.length <= 8){
                for (let i=0; i<this.CARDS.length; i++){
                    this.CARDS[i].visible = false;
                }
                this.InfoView.visible = !this.InfoView.visible;
            }
            HSceneManager.INSTANCE.animate();
        });
        addButton.scale.set(0.5,0.5);

        this.INFOSCREEN.ConnectFallback((id) => {
            this.ITEMS.push(id);
            this.UpdateScene();
            HSceneManager.INSTANCE.animate();
            
            if (this.CARDS.length > 8){
                addButton.interactive = false;
                addButton.visible = false;
            }
        },() => {
            this.InfoView.visible = !this.InfoView.visible;
            for (let i=0; i<this.CARDS.length; i++){
                this.CARDS[i].visible = true;
            }
            if (this.CARDS.length > 8){
                addButton.interactive = false;
                addButton.visible = false;
            }
            HSceneManager.INSTANCE.animate();
        });
        this.InfoView = this.INFOSCREEN.BuildScene();
        this.InfoView.interactive = true;
        this.InfoView.visible = false;

        this.viewReference.addChild(background);
        this.viewReference.addChild(form);
        this.viewReference.addChild(description);
        this.viewReference.addChild(this.investigatorTargetText);
        this.viewReference.addChild(back);
        this.viewReference.addChild(send);
        this.viewReference.addChild(this.checkedView);
        this.viewReference.addChild(addButton);

        if (this.type != 3){
            this.viewReference.addChild(levelText);
        }
        this.viewReference.addChild(this.InfoView);

        this.UpdateScene();

        return this.viewReference;
    }

    LoadResources(): void {
        if (PRequestScreen.loaded){
            return;
        }
        this.AddResource('background', 'Background.png');
        this.AddResource('back', 'BackButton.png');
        this.AddResource('back_hover', 'BackButton_Hover.png');
        this.AddResource('back_click', 'BackButton_Click.png');
        
        this.AddResource('send', 'SendButton.png');
        this.AddResource('send_hover', 'SendButton_Hover.png');
        this.AddResource('send_click', 'SendButton_Click.png');

        this.AddResource('aca_form', 'ACA_RequestForm.png');
        this.AddResource('as_form', 'AS_RequestForm.png');
        this.AddResource('fi_form', 'FI_RequestForm.png');
        
        this.AddResource('check', 'Request_Check.png');
        this.AddResource('uncheck', 'Request_UnCheck.png');

        this.AddResource('add', 'Request_AddButton.png');
        this.AddResource('add_click', 'Request_AddButton_Click.png');
        
        this.INFOSCREEN.LoadResources();
        PRequestScreen.loaded = true;
    }
    
    GetBriefingText(): string {
        switch (this.type){
            case 3:
                return 'Select on the top what asset you would like to request the seizure for.\r\r\rPlease draft your assertion at the bottom to explain your case for requesting this seizure.';
            default:
                return 'Select on the top who you would like to request the investigation for.\r\r\rPlease draft your assertion at the bottom to explain your case for requesting this investigation.';
    
        }
    }

    GetBriefingTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontSize: 22
        });
    }

    GetLinkText(): string {
        switch (this.type){
            case 3:
                return '- Select your asset of choice -';
            default:
                return '- Select your investigation target -';
        }
    }

    GetLinkTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontStyle: 'bold',
            fontSize: 22
        });
    }
}
