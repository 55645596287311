import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NyiComponent } from './nyi/nyi.component';

import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';

import { InformationlistComponent } from './informationlist/informationlist.component';
import { InformationsingleComponent } from './informationsingle/informationsingle.component';
import { RequestlistComponent } from './requestlist/requestlist.component';
import { FilterSelectionTypeComponent } from './filter-selection-type/filter-selection-type.component';
import { FilterSelectionSuspectComponent } from './filter-selection-suspect/filter-selection-suspect.component';
import { FilterSelectionLevelComponent } from './filter-selection-level/filter-selection-level.component';
import { ErrorDialogMessageComponent } from './error-dialog-message/error-dialog-message.component';
import { SuccessDialogMessageComponent } from './success-dialog-message/success-dialog-message.component';
import { DeadEndDialogMessageComponent } from './dead-end-dialog-message/dead-end-dialog-message.component';
import { ConfirmPointsDialogComponent } from './confirm-points-dialog/confirm-points-dialog.component';
import { LibrarylistComponent } from './librarylist/librarylist.component';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    AppComponent,
    NyiComponent,
    InformationlistComponent,
    InformationsingleComponent,
    RequestlistComponent,
    FilterSelectionTypeComponent,
    FilterSelectionSuspectComponent,
    FilterSelectionLevelComponent,
    ErrorDialogMessageComponent,
    SuccessDialogMessageComponent,
    DeadEndDialogMessageComponent,
    ConfirmPointsDialogComponent,
    LibrarylistComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatListModule,
    MatButtonModule
  ],
  providers: [
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
