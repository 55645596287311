import * as PIXI from 'pixi.js-legacy';
import { HScene } from '../helpers/h-scene';
import { HSceneManager } from '../helpers/h-scenemanager';
import { IScene } from '../interfaces/i-scene';
import { PButton } from './pbutton';

export class PPhoneCallScreen extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/PhoneConversation';
    PREFIX = 'PhoneConversation';
    CALLBACK: () => void = null;
    HEADER = null;

    NAMELABEL= null;
    YOULABEL= null;
    NAMERESPONSE = null;
    YOURRESPONSE = null;

    constructor(){
        super();
    }
    UpdateScene(): void {}

    ConnectFallback(connectFeedback: () => void): void {
        this.CALLBACK = connectFeedback;
    }

    ChangeHeader(name: string){
        this.HEADER.text = name;
        this.NAMELABEL.text = name;
    }

    BuildScene(): PIXI.Container {
        const view = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        const header_back = this.LoadSprite('header',1020,100);
        const box1 = this.LoadSprite('box1',720,550);
        box1.anchor.set(0.5,0.5);
        const box2 = this.LoadSprite('box2',1220,300);
        box2.anchor.set(0.5,0.5);
        header_back.anchor.set(0.5,0.5);

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            1020, 740
        );
        back.anchor.set(0.5,0.5);
        back.on('click', (event) => {
            this.CALLBACK();
            HSceneManager.INSTANCE.animate();
        });

        this.HEADER = this.LoadText('NAME', 1020, 100);
        this.HEADER.style = this.GetHeaderStyle();
        this.HEADER.anchor.set(0.5,0.5);

        this.NAMELABEL = this.LoadText('NAME', 720, 475);
        this.NAMELABEL.style = this.GetNameTextStyle();
        this.NAMELABEL.anchor.set(0.5,0.5);

        this.NAMERESPONSE = this.LoadText('Hello,\nthere is nothing left to discuss.\nI have send you everything via mail.', 470, 520);
        this.NAMERESPONSE.style = this.GetTextStyle();

        this.YOULABEL = this.LoadText('You', 1220, 225);
        this.YOULABEL.style = this.GetNameTextStyle();
        this.YOULABEL.anchor.set(0.5,0.5);
        
        this.YOURRESPONSE = this.LoadText('Hey,\ndo you have a quick moment to discuss our current case?', 970, 270);
        this.YOURRESPONSE.style = this.GetTextStyle();

        view.addChild(background);
        view.addChild(header_back);
        view.addChild(this.HEADER);
        view.addChild(box1);
        view.addChild(box2);
        view.addChild(this.NAMELABEL);
        view.addChild(this.YOULABEL);
        view.addChild(this.YOURRESPONSE);
        view.addChild(this.NAMERESPONSE);
        view.addChild(back);

        return view;
    }

    LoadResources(): void {
        if (PPhoneCallScreen.loaded){
            return;
        }
        this.AddResource('background', 'Background.png');
        this.AddResource('back_hover', 'BackButton_Hover.png');
        this.AddResource('back_click', 'BackButton_Click.png');
        this.AddResource('back', 'BackButton.png');
        this.AddResource('header', 'Felduberschrift.png');
        this.AddResource('box1', 'box1.png');
        this.AddResource('box2', 'box2.png');
        PPhoneCallScreen.loaded = true;
    }

    GetNameTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontStyle: 'bold',
            fontSize: 26
        });
    }

    GetTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontSize: 22
        });
    }

    GetHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }
}
