import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-dialog-message',
  templateUrl: './success-dialog-message.component.html',
  styleUrls: ['./success-dialog-message.component.css']
})
export class SuccessDialogMessageComponent implements OnInit {
  
  message: string = "";
  constructor() { }

  ngOnInit(): void {
  }

}
