import { MatDialog } from '@angular/material/dialog';
import * as PIXI from 'pixi.js-legacy';
import { IScene } from '../interfaces/i-scene';
import { PCard } from '../pixielements/pcard';
import { SuccessDialogMessageComponent } from '../success-dialog-message/success-dialog-message.component';

export class HSceneManager {
    static INSTANCE: HSceneManager = null;
    
    APP: PIXI.Application;
    DIALOG: MatDialog;
    SCENES: IScene[] = [];

    constructor(app: PIXI.Application, dialog: MatDialog){
        this.APP = app;
        this.DIALOG = dialog;
        HSceneManager.INSTANCE = this;
    }

    addScene(scene: IScene, index: number): void {
        this.SCENES[index] = scene;
    }

    changeScene(scene: IScene): void{
        this.APP.stage.destroy({children:true});
        this.APP.stage = null;
        this.APP.stage = scene.BuildScene();
        this.animate();
    }

    changeSceneById(sceneIndex: number): void{
        this.changeScene(this.SCENES[sceneIndex]);
    }

    showMessage(text: string){
        let successMessage = this.DIALOG.open(SuccessDialogMessageComponent);
        successMessage.componentInstance.message = text;
    }

    LoadAllResources(): void {
        PCard.LoadResources();

        this.SCENES.forEach((scene) => {
            if ((scene as any).PREFIX != "LoadScreen_"){
                scene.LoadResources();
            }
        });

        PIXI.Loader.shared.load(() => {
            if (this.SCENES.length > 0){
                this.changeScene(this.SCENES[0]);
            }
        });

      }

    animate(): void {
        PIXI.Ticker.shared.update(performance.now());
        this.APP.renderer.render(this.APP.stage);
    }
}
