import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { EScene } from '../enums/e-scene';
import { PButton } from '../pixielements/pbutton';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { NyiComponent } from '../nyi/nyi.component';
import { MatDialog } from '@angular/material/dialog';

export class MainMenu extends HScene implements IScene {
    ASSET_FOLDER = this.ASSET_FOLDER + 'MenuScreenPNGs';
    PREFIX = 'MainMenu_';
    dialog: MatDialog;

    constructor(dialog: MatDialog){
        super();
        this.dialog = dialog;
    }

    UpdateScene(): void {}
    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);

        // Random Pen
        const pen = this.LoadSprite('pen',1312,475);

        // settings Button
        const settings = new PButton(
            this.LoadTexture('settings'),
            this.LoadTexture('settings_hover'),
            this.LoadTexture('settings_click'),
            883, 722
        );
        settings.on('click', (event) => {
            this.GoToSettings();
        });


        // privacy Button
        const privacy = new PButton(
            this.LoadTexture('privacy'),
            this.LoadTexture('privacy_hover'),
            this.LoadTexture('privacy_click'),
            120, 671
        );
        privacy.on('click', (event) => {
            this.openDialog();
        });

        // newgame Button
        const newgame = new PButton(
            this.LoadTexture('newgame'),
            this.LoadTexture('newgame_hover'),
            this.LoadTexture('newgame_click'),
            555, 102
        );
        newgame.on('click', (event) => {
            this.JumpToNewGame();
        });

        // loadgame Button
        const loadgame = new PButton(
            this.LoadTexture('loadgame'),
            this.LoadTexture('loadgame_hover'),
            this.LoadTexture('loadgame_click'),
            940, 368
        );
        loadgame.on('click', (event) => {
            this.GoToLoadGame();
        });

        // imprint Button
        const imprint = new PButton(
            this.LoadTexture('imprint'),
            this.LoadTexture('imprint_hover'),
            this.LoadTexture('imprint_click'),
            1248, 812
        );
        imprint.on('click', (event) => {
            this.openDialog();
        });

        // Add Elements to Layer
        container.addChild(background);
        container.addChild(imprint);
        container.addChild(loadgame);
        container.addChild(newgame);
        container.addChild(privacy);
        container.addChild(settings);
        container.addChild(pen);
        return container;
    }

    
  openDialog() {
    this.dialog.open(NyiComponent);
  }

    LoadResources(): void {
        this.AddResource('background',      'MenuScreen_Background.png');
        this.AddResource('imprint',         'MenuScreen_ImprintButton.png');
        this.AddResource('imprint_click',   'MenuScreen_ImprintButton_Click.png');
        this.AddResource('imprint_hover',   'MenuScreen_ImprintButton_Hover.png');
        this.AddResource('loadgame',        'MenuScreen_LoadGameButton.png');
        this.AddResource('loadgame_click',  'MenuScreen_LoadGameButton_Click.png');
        this.AddResource('loadgame_hover',  'MenuScreen_LoadGameButton_Hover.png');
        this.AddResource('newgame',         'MenuScreen_NewGameButton.png');
        this.AddResource('newgame_click',   'MenuScreen_NewGameButton_Click.png');
        this.AddResource('newgame_hover',   'MenuScreen_NewGameButton_Hover.png');
        this.AddResource('privacy',         'MenuScreen_PrivacyPolicyButton.png');
        this.AddResource('privacy_click',   'MenuScreen_PrivacyPolicyButton_Click.png');
        this.AddResource('privacy_hover',   'MenuScreen_PrivacyPolicyButton_Hover.png');
        this.AddResource('settings',        'MenuScreen_Settingsbutton.png');
        this.AddResource('settings_click',  'MenuScreen_Settingsbutton_Click.png');
        this.AddResource('settings_hover',  'MenuScreen_Settingsbutton_Hover.png');
        this.AddResource('pen',             'MenuScreen_Pen.png');
    }

    JumpToNewGame(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.NEWGAMESCREEN);
    }

    GoToSettings(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.SETTINGSSCREEN);
    }

    GoToLoadGame(): void {
        HSceneManager.INSTANCE.changeSceneById(EScene.LOADGAMESCREEN);
    }

}
