import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { InformationsingleComponent } from '../informationsingle/informationsingle.component';

@Component({
  selector: 'app-informationlist',
  templateUrl: './informationlist.component.html',
  styleUrls: ['./informationlist.component.css']
})
export class InformationlistComponent implements OnInit {

  informationTypes = [
    {name: 'Mr.Eddy', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. ', pic: './assets/PhantomBilder/Face17.png', hint: null},
    {name: 'Mr.Eddys Family', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. ', pic: './assets/PhantomBilder/Face18.png', hint: null},
    {name: 'Swoop Co. Law', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. ', pic: null, hint: './assets/Email/hint1.png'},
    {name: 'Bank Account: 011536987239', info: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. Lorem ipsum\ndolor sit amet, consectetur adipiscing elit. Donec eleifend erat tortor,\n\neu efficitur mauris scelerisque sed. Nam accumsan tortor et ex bibendum,\nvitae luctus magna volutpat. ', pic: null, hint: './assets/Email/hint2.png'}
  ];

  dialog: MatDialog;

  constructor(dialog: MatDialog) {
    this.dialog = dialog;
  }

  ngOnInit(): void {
  }

  close(info: any){
    console.log(info);
    this.dialog.closeAll();
    this.openDialog(info);
  }

  openDialog(info: any){
    let dialogRef = this.dialog.open(InformationsingleComponent);
    dialogRef.componentInstance.info = info;
  }

}
