import * as PIXI from 'pixi.js-legacy';
import { HScene } from '../helpers/h-scene';
import { HSceneManager } from '../helpers/h-scenemanager';
import { IScene } from '../interfaces/i-scene';
import { PButton } from './pbutton';

export class PPhoneAddressScreen extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/AddressBook';
    PREFIX = 'AddressBook';
    CALLBACK: () => void = null;
    FORWARDCALLBACK: (data) => void = null;

    constructor(){
        super();
    }
    UpdateScene(): void {}

    ConnectFallback(connectFeedback: () => void, connectResponse: (data) => void): void {
        this.CALLBACK = connectFeedback;
        this.FORWARDCALLBACK = connectResponse;
    }

    BuildScene(): PIXI.Container {
        const view = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        const book = this.LoadSprite('background_book',1020,512);
        book.anchor.set(0.5,0.5);
        const header_back = this.LoadSprite('header',1020,100);
        header_back.anchor.set(0.5,0.5);

        // back Button
        const back = new PButton(
            this.LoadTexture('back'),
            this.LoadTexture('back_hover'),
            this.LoadTexture('back_click'),
            560, 955
        );
        back.anchor.set(0.5,0.5);
        back.on('click', (event) => {
            this.CALLBACK();
            HSceneManager.INSTANCE.animate();
        });

        const header = this.LoadText(this.GetHeader(), 1020, 100);
        header.style = this.GetHeaderStyle();
        header.anchor.set(0.5,0.5);

        const noNumber = this.LoadText("No phone number unlocked", 680, 260);
        noNumber.style = this.GetLinkTextStyle();
        noNumber.anchor.set(0.5,0.5);

        /*const pers1 = this.LoadText("Mr. Eddy", 680, 260);
        pers1.style = this.GetLinkTextStyle();
        pers1.anchor.set(0.5,0.5);
        pers1.interactive = true;
        pers1.buttonMode = true;

        const pers2 = this.LoadText("Mrs. Eddy", 680, 364);
        pers2.style = this.GetLinkTextStyle();
        pers2.anchor.set(0.5,0.5);
        pers2.interactive = true;
        pers2.buttonMode = true;

        const addr1 = this.LoadText("0441-2013", 900, 260);
        addr1.style = this.GetLinkTextStyle();
        addr1.anchor.set(0.5,0.5);
        addr1.interactive = true;
        addr1.buttonMode = true;

        const addr2 = this.LoadText("0176-23612", 900, 364);
        addr2.style = this.GetLinkTextStyle();
        addr2.anchor.set(0.5,0.5);
        addr2.interactive = true;
        addr2.buttonMode = true;

        pers1.on('click', (event) => {
            this.FORWARDCALLBACK("Mr. Eddy");
            HSceneManager.INSTANCE.animate();
        });
        pers2.on('click', (event) => {
            this.FORWARDCALLBACK("Mrs. Eddy");
            HSceneManager.INSTANCE.animate();
        });
        addr1.on('click', (event) => {
            this.FORWARDCALLBACK("Mr. Eddy");
            HSceneManager.INSTANCE.animate();
        });
        addr2.on('click', (event) => {
            this.FORWARDCALLBACK("Mrs. Eddy");
            HSceneManager.INSTANCE.animate();
        });*/

        view.addChild(background);
        view.addChild(book);
        view.addChild(header_back);
        view.addChild(header); 
        //view.addChild(pers1);
        //view.addChild(pers2);
        //view.addChild(addr1);
        //view.addChild(addr2);
        view.addChild(noNumber);
        view.addChild(back);

        return view;
    }

    LoadResources(): void {
        if (PPhoneAddressScreen.loaded){
            return;
        }
        this.AddResource('background', 'Background.png');
        this.AddResource('background_book', 'AddressBook.png');
        this.AddResource('back', 'BackButton.png');
        this.AddResource('back_hover', 'BackButton_Hover.png');
        this.AddResource('back_click', 'BackButton_Click.png');
        this.AddResource('header', 'Felduberschrift.png');
        PPhoneAddressScreen.loaded = true;
    }

    GetHeader(): string {
        return 'Address Book';
    }

    GetLinkTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 477,
            fontStyle: 'bold',
            fontSize: 22
        });
    }

    GetHeaderStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 36,
            fontWeight: 'bold'
        });
    }
}
