import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PGameState } from '../piximodels/pgamestate';

@Component({
  selector: 'app-requestlist',
  templateUrl: './requestlist.component.html',
  styleUrls: ['./requestlist.component.css']
})
export class RequestlistComponent implements OnInit {

  filterSuspects = [
    {name: 'All', value: -1}
  ];

  filterAccounts = [
    {name: 'All', value: -1}
  ];

  filterAssets = [
    {name: 'All', value: -1}
  ];

  type: Number;

  CALLBACK: (name,value) => void = null;

  dialog: MatDialog;

  constructor(dialog: MatDialog) {
    this.dialog = dialog;
    this.loadData();
  }

  ngOnInit(): void {
    this.loadData();
  }

  close(info: any){
    this.CALLBACK(info.name,info.value);
    this.dialog.closeAll();
  }

  loadData(): void {
    this.filterSuspects = [];
    this.filterAccounts = [];
    this.filterAssets = [];

    for (let i=0; i<PGameState.unlockedTargets.length; i++){
      let target = PGameState.allTargets[PGameState.unlockedTargets[i]];
      if (target.type == 0 || target.type == 1 || target.type == 3){
        this.filterSuspects.push({name: target.name, value: target.id});
      }
      if (target.type == 2){
        this.filterAccounts.push({name: target.name, value: target.id});
      }
      if (target.type == 4){
        this.filterAssets.push({name: target.name, value: target.id});
      }
    }
  }

}
