import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';

export class PButton extends PIXI.Sprite {
    textureNormal: PIXI.Texture;
    textureHover: PIXI.Texture;
    textureClick: PIXI.Texture;
    isMouseOver = false;
    hoverSprite: PIXI.Sprite = null;

    constructor(textureNormal: PIXI.Texture, textureHover: PIXI.Texture, textureClick: PIXI.Texture, x: number, y: number, hoverSprite?: PIXI.Sprite) {
        super(textureNormal);
        this.textureNormal = textureNormal;
        this.textureHover = textureHover;
        this.textureClick = textureClick;
        this.x = x;
        this.y = y;
        this.interactive = true;
        if (hoverSprite){
            this.hoverSprite = hoverSprite;
        }
        this.on('mouseover', (event: any) => {
            this.texture = this.textureHover;
            this.isMouseOver = true;
            if (this.hoverSprite){
                this.hoverSprite.visible = true;
            }
            HSceneManager.INSTANCE.animate();
        });
        this.on('mouseout', (event: any) => {
            this.texture = this.textureNormal;
            this.isMouseOver = false;
            if (this.hoverSprite){
                this.hoverSprite.visible = false;
            }
            HSceneManager.INSTANCE.animate();
        });
        this.on('mousedown', (event: any) => {
            this.texture = this.textureClick;
            HSceneManager.INSTANCE.animate();
        });
        this.on('mouseup', (event: any) => {
            if (this.isMouseOver){
                this.texture = this.textureHover;
            } else {
                this.texture = this.textureNormal;
            }
            HSceneManager.INSTANCE.animate();
        });
    }
}
