import * as PIXI from 'pixi.js-legacy';
import { EScene } from '../enums/e-scene';
import { HScene } from '../helpers/h-scene';
import { HSceneManager } from '../helpers/h-scenemanager';
import { IScene } from '../interfaces/i-scene';
import { PButton } from './pbutton';
import { PHintScreen } from './phintscreen';
import { PCharacterScreen } from './pcharacterscreen';
import { PGameState } from './../piximodels/pgamestate';
import { saveAs } from 'file-saver';

export class PSideNavigation extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/Menus/SideMenu';
    PREFIX = 'SideNavigation';
    NAVIGATION = 2;

    HINT: PHintScreen = null;
    HintView: PIXI.Container;

    CHARACTER: PCharacterScreen = null;
    CharacterView: PIXI.Container;

    CHARACTERFRAME = null;

    REFERENCESCENE = null;

    constructor(navigation: number, reference:IScene){
        super();
        this.NAVIGATION = navigation;
        
        this.HINT = new PHintScreen();
        this.CHARACTER = new PCharacterScreen();

        this.CHARACTERFRAME = null;
        this.REFERENCESCENE = reference;
    }

    UpdateScene(): void {}

    BuildScene(): PIXI.Container {
        const view = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        const background2 = this.LoadSprite('background2',0,20);
        this.CHARACTERFRAME = this.LoadSprite(this.GetCharAsset(),1734,21);
        const resource_background = this.LoadSprite('resource_background',1710,191);
        const resource_text = this.LoadText(this.GetResourceText(), 81, 34);
        resource_text.style = this.GetResourceTextStyle();
        resource_text.anchor.set(0.5,0.5);

        // hint Button
        const character_background = new PButton(
            this.LoadTexture('character_background'),
            this.LoadTexture('character_background'),
            this.LoadTexture('character_background'),
            1690, 20
        );
        character_background.on('click', (event) => {
            this.CharacterView.visible = !this.CharacterView.visible;
            HSceneManager.INSTANCE.animate();
            if (PGameState.level == 1 && !PGameState.characterscreen_seen){
                HSceneManager.INSTANCE.showMessage("<b>Change your roles to perform different actions!</b><br><br>This is your <b>Character Selection</b>. Here you can switch your role to be either a <b>Anti Corruption Specialist</b>, <b>Finance Specialist</b> or a <b>Public Prosecutor</b>.<br><br>In Follow the Money, the Anti Corruption Specialist has access to request <b>ACA Investigations</b>, while the Finance Specialist can request <b>Financial Investigations</b>.<br>Public Prosecutors are responsible for <b>Court Order Requests</b> and <b>Asset Seizure</b>.<br><br>You can change between roles by clicking the arrow buttons at the bottom of the screen.");
                PGameState.characterscreen_seen = true;
            }
        });

        this.HINT.ConnectFallback(() => {
            this.HintView.visible = !this.HintView.visible;
            HSceneManager.INSTANCE.animate();
        });
        this.HintView = this.HINT.BuildScene();
        this.HintView.interactive = true;
        this.HintView.visible = false;

        this.CHARACTER.ConnectFallback(() => {
            this.CharacterView.visible = !this.CharacterView.visible;
            HSceneManager.INSTANCE.animate();
        },
        () => {
            this.CHARACTERFRAME.texture = this.LoadTexture(this.GetCharAsset());
            this.REFERENCESCENE.UpdateScene();
            HSceneManager.INSTANCE.animate();
        }
        );

        this.CharacterView = this.CHARACTER.BuildScene();
        this.CharacterView.interactive = true;
        this.CharacterView.visible = false;

        // menu Button
        const menu = new PButton(
            this.LoadTexture('toMenu'),
            this.LoadTexture('toMenu'),
            this.LoadTexture('toMenu_click'),
            70, 55
        );
        menu.anchor.set(0.5,0.5);
        menu.on('click', (event) => {
            window.location.reload();
        });
        menu.scale.set(0.5);

        // hint Button
        const save = new PButton(
            this.LoadTexture('save'),
            this.LoadTexture('save'),
            this.LoadTexture('save'),
            1590, 960
        );
        save.on('click', (event) => {
            const blob = 
            new Blob([
                JSON.stringify(PGameState.save())], 
                 {type: "application/json;charset=utf-8"});
            saveAs(blob, "Follow_the_money_" + new Date().toISOString()+ ".save");
        });

        // hint Button
        const hint = new PButton(
            this.LoadTexture('hint'),
            this.LoadTexture('hint_hover'),
            this.LoadTexture('hint_click'),
            1690, 810
        );
        hint.on('click', (event) => {
            this.HintView.visible = !this.HintView.visible;
            HSceneManager.INSTANCE.animate();
            if (PGameState.level == 1 && !PGameState.hintscreen_seen){
                HSceneManager.INSTANCE.showMessage("<b>Do you need any Hints?</b><br><br>This is your guide for all game scenarios in Follow the Money giving you different <b>Hints</b> at different stages of the game. If you are stuck, feel free to check what he has to say.");
                PGameState.hintscreen_seen = true;
            }
        });

        // computer Button
        const computer = new PButton(
            this.LoadTextureDependency('computer','computer_active', (this.NAVIGATION == 0)),
            this.LoadTextureDependency('computer_hover','computer_click', (this.NAVIGATION == 0)),
            this.LoadTexture('computer_click'),
            122, 570
        );
        computer.anchor.set(0.5,0.5);
        computer.on('click', (event) => {
            this.GoToComputer();
            if (PGameState.level == 1  && !PGameState.computer_seen){
                HSceneManager.INSTANCE.showMessage("<b>You discovered your computer!</b><br><br>As you have already discovered in the beginning of the game, your computer gives you access to your <b>E-Mails</b>. Those might contain additional informations on cases and function as an inbox of successfully seized assets.<br>In addition to your E-Mails, you may also access the <b>Web</b> from your computer. You can access unique news articles from there, which are documenting your current progress. If you are not sure how far you have progressed, you might want to have a quick peak to get an understanding of your current progression.<br><br>If you have any kind of <b>Feedback</b> for this game, you can find a dedicated icon on the computer to open your actual mail program of choice!");
                PGameState.computer_seen = true;
            }
        });

        // filefolder Button
        const filefolder = new PButton(
            this.LoadTextureDependency('filefolder','filefolder_active', (this.NAVIGATION == 1)),
            this.LoadTextureDependency('filefolder_hover','filefolder_click', (this.NAVIGATION == 1)),
            this.LoadTexture('filefolder_click'),
            122, 380
        );
        filefolder.anchor.set(0.5,0.5);
        filefolder.on('click', (event) => {
            this.GoToFiles();
            if (PGameState.level == 1 && !PGameState.filefolder_seen){
                HSceneManager.INSTANCE.showMessage("<b>This is your file folder!</b><br><br>Here you can view your available evidence in the <b>Information</b> section. Depending on your current role, you can also start different kind of investigations and start asset seizures from here by filling out the required forms for them. Start by trying a simple <b>ACA Investigation</b> on a target of your choice!<br><br>If you need any general advice on money laundering, you can check out the <b>Library</b> to view some literature regarding that topic.");
                PGameState.filefolder_seen = true;
            }
        });

        // office Button
        const office = new PButton(
            this.LoadTextureDependency('office','office_active', (this.NAVIGATION == 2)),
            this.LoadTextureDependency('office_hover','office_click', (this.NAVIGATION == 2)),
            this.LoadTexture('office_click'),
            122, 190
        );
        office.anchor.set(0.5,0.5);
        office.on('click', (event) => {
            this.GoToOffice();
            if (PGameState.level == 1 && !PGameState.officeview_seen){
                HSceneManager.INSTANCE.showMessage("<b>Welcome to your Office!</b><br><br>This is the central game hub of <b>Follow the Money</b>. From here you can access the file folder, computer, telephone and whiteboard!");
                PGameState.officeview_seen = true;
            }
        });

        // telephone Button
        const telephone = new PButton(
            this.LoadTextureDependency('telephone','telephone_active', (this.NAVIGATION == 3)),
            this.LoadTextureDependency('telephone_hover','telephone_click', (this.NAVIGATION == 3)),
            this.LoadTexture('telephone_click'),
            122, 760
        );
        telephone.anchor.set(0.5,0.5);
        telephone.on('click', (event) => {
            this.GoToTelephone();
            if (PGameState.level == 1 && !PGameState.telephone_seen){
                HSceneManager.INSTANCE.showMessage("<b>This is your Telephone!</b><br><br>During your investigations, you might unlock phone numbers of certain individuals, which are shown and accessible in your <b>Address Book</b>. Although talking to Suspects will not give you any new usable evidence, it may reveal new suspects to potentially investigate.");
                PGameState.telephone_seen = true;
            }
        });

        // whiteboard Button
        const whiteboard = new PButton(
            this.LoadTextureDependency('whiteboard','whiteboard_active', (this.NAVIGATION == 4)),
            this.LoadTextureDependency('whiteboard_hover','whiteboard_click', (this.NAVIGATION == 4)),
            this.LoadTexture('whiteboard_click'),
            122, 950
        );
        whiteboard.anchor.set(0.5,0.5);
        whiteboard.on('click', (event) => {
            this.GoToWhiteboard();
            if (PGameState.level == 1 && !PGameState.whiteboard_seen){
                HSceneManager.INSTANCE.showMessage("<b>Say Hello to your Whiteboard!</b><br><br>On this whiteboard, you can see all key relations proven by your available evidence. Whenever you discover new evidence, additional connections are automatically added to showcase the current relations of your investigations.");
                PGameState.whiteboard_seen = true;
            }
        });

        view.addChild(background2);
        view.addChild(background);
        view.addChild(computer);
        view.addChild(filefolder);
        view.addChild(office);
        view.addChild(telephone);
        view.addChild(whiteboard);
        view.addChild(menu);
        view.addChild(character_background);
        view.addChild(this.CHARACTERFRAME);
        resource_background.addChild(resource_text);
        view.addChild(resource_background);
        view.addChild(hint);
        view.addChild(save);
        view.addChild(this.HintView);
        view.addChild(this.CharacterView);

        return view;
    }

    LoadResources(): void {
        if (PSideNavigation.loaded){
            return;
        }
        this.AddResource('background', 'MenuLayer2.png');
        this.AddResource('background2', 'MenuLayer1.png');
        this.AddResource('computer', 'MenuComputer_Neutral.png');
        this.AddResource('filefolder', 'MenuFileFolder_Neutral.png');
        this.AddResource('office', 'MenuOfficeView_Neutral.png');
        this.AddResource('telephone', 'MenuTelephone_Neutral.png');
        this.AddResource('whiteboard', 'MenuWhiteboard_Neutral.png');
        this.AddResource('computer_hover', 'MenuComputer_Neutral_Hover.png');
        this.AddResource('filefolder_hover', 'MenuFileFolder_Neutral_Hover.png');
        this.AddResource('office_hover', 'MenuOfficeView_Neutral_Hover.png');
        this.AddResource('telephone_hover', 'MenuTelephone_Neutral_Hover.png');
        this.AddResource('whiteboard_hover', 'MenuWhiteboard_Neutral_Hover.png');
        this.AddResource('computer_click', 'MenuComputer_Selected_Hover.png');
        this.AddResource('filefolder_click', 'MenuFileFolder_Selected_Hover.png');
        this.AddResource('office_click', 'MenuOfficeView_Selected_Hover.png');
        this.AddResource('telephone_click', 'MenuTelephone_Selected_Hover.png');
        this.AddResource('whiteboard_click', 'MenuWhiteboard_Selected_Hover.png');
        this.AddResource('computer_active', 'MenuComputer_Selected.png');
        this.AddResource('filefolder_active', 'MenuFileFolder_Selected.png');
        this.AddResource('office_active', 'MenuOfficeView_Selected.png');
        this.AddResource('telephone_active', 'MenuTelephone_Selected.png');
        this.AddResource('whiteboard_active', 'MenuWhiteboard_Selected.png');
        this.AddResource('char1', 'AntiCorruptionSpecialist_Crop.png');
        this.AddResource('char2', 'FinanceSpecialist_Crop.png');
        this.AddResource('char3', 'PublicProsecutor_Crop.png');
        this.AddResource('character_background', 'CharacterMenu.png');
        this.AddResource('resource_background', 'ResourceMenu.png');
        this.AddResource('hint', 'HintScreen_Symbol.png');
        this.AddResource('hint_click', 'HintScreen_Symbol_Click.png');
        this.AddResource('hint_hover', 'HintScreen_Symbol_Hover.png');
        this.AddResource('toMenu', 'MenuButton_Back.png');
        this.AddResource('toMenu_click', 'MenuButton_Click.png');
        this.AddResource('save', 'SaveButton.png');
        this.HINT.LoadResources();
        this.CHARACTER.LoadResources();
        PSideNavigation.loaded = true;
    }

    LoadTextureDependency(key1: string, key2: string, check: boolean): PIXI.Texture {
        if (check){
            return this.LoadTexture(key2);
        } else {
            return this.LoadTexture(key1);
        }
    }

    GetCharAsset(): string {
        switch (PGameState.character){
            case 0:
                return 'char1';
            case 1:
                return 'char2';
            case 2:
                return 'char3';
            default:
                return 'char1';
        }
    }

    GoToOffice(){
        HSceneManager.INSTANCE.changeSceneById(EScene.OFFICESCREEN); 
    }

    GoToComputer(){
        HSceneManager.INSTANCE.changeSceneById(EScene.COMPUTERSCREEN); 
    }

    GoToFiles(){
        HSceneManager.INSTANCE.changeSceneById(EScene.FILEFOLDERSCREEN);
    }

    GoToTelephone(){
        HSceneManager.INSTANCE.changeSceneById(EScene.TELEPHONESCREEN);
    }

    GoToWhiteboard(){
        HSceneManager.INSTANCE.changeSceneById(EScene.WHITEBOARDSCREEN);
    }

    GetResourceText(): string {
        return PGameState.steps + ' Steps left';
    }

    GetResourceTextStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            wordWrap: true,
            wordWrapWidth: 151,
            fontSize: 24
        });
    }
}
