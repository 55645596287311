import { PCardModel } from './pcardmodel';
import { PTargetModel } from './ptargetmodel';
import  * as cards_3 from "../../assets/Data/3/cards.json";
import  * as cards_2 from "../../assets/Data/2/cards.json";
import  * as cards_1 from "../../assets/Data/1/cards.json";
import  * as targets_3 from "../../assets/Data/3/targets.json";
import  * as targets_2 from "../../assets/Data/2/targets.json";
import  * as targets_1 from "../../assets/Data/1/targets.json";
import { MatDialog } from '@angular/material/dialog';
import { DeadEndDialogMessageComponent } from '../dead-end-dialog-message/dead-end-dialog-message.component';
import { ErrorDialogMessageComponent } from '../error-dialog-message/error-dialog-message.component';
import { SuccessDialogMessageComponent } from '../success-dialog-message/success-dialog-message.component';

export class PGameState {
    static character = 0;
    static steps = 100;
    static unlockedCards:number[] = [];
    static allCards:PCardModel[] = [];
    static unlockedTargets:number[] = [];
    static allTargets:PTargetModel[] = [];
    static officeview_seen = false;
    static filefolder_seen = false;
    static computer_seen = false;
    static telephone_seen = false;
    static whiteboard_seen = false;
    static hintscreen_seen = false;
    static characterscreen_seen = false;
    static aca_seen = false;
    static fi_seen = false;
    static as_seen = false;
    static library_seen = false;
    static evidence_seen = false;
    static courtorder_seen = false;
    static level = 0;
    static seizedAssets:number[] = [];

    static save(): any {
        return {
            character: PGameState.character,
            steps: PGameState.steps,
            unlockedCards: PGameState.unlockedCards,
            allCards: PGameState.allCards,
            unlockedTargets: PGameState.unlockedTargets,
            allTargets: PGameState.allTargets,
            level: PGameState.level,
            officeview_seen: PGameState.officeview_seen,
            filefolder_seen: PGameState.filefolder_seen,
            computer_seen: PGameState.computer_seen,
            telephone_seen: PGameState.telephone_seen,
            whiteboard_seen: PGameState.whiteboard_seen,
            hintscreen_seen: PGameState.hintscreen_seen,
            characterscreen_seen: PGameState.characterscreen_seen,
            aca_seen: PGameState.aca_seen,
            fi_seen: PGameState.fi_seen,
            as_seen: PGameState.as_seen,
            library_seen: PGameState.library_seen,
            evidence_seen: PGameState.evidence_seen,
            courtorder_seen: PGameState.courtorder_seen,
            seizedAssets: PGameState.seizedAssets
        }
    }

    static load(json): boolean {
        PGameState.character = json.character;
        PGameState.steps = json.steps;
        PGameState.unlockedCards = json.unlockedCards;
        PGameState.allCards = json.allCards;
        PGameState.unlockedTargets = json.unlockedTargets;
        PGameState.allTargets = json.allTargets;
        PGameState.level = json.level;

        if (PGameState.character == undefined || 
            PGameState.steps == undefined ||
            PGameState.unlockedCards == undefined ||
            PGameState.allCards == undefined ||
            PGameState.unlockedTargets == undefined ||
            PGameState.allTargets == undefined ||
            PGameState.level == undefined)
        {
            return false;
        }
        
        PGameState.officeview_seen = json.officeview_seen;
        PGameState.filefolder_seen = json.filefolder_seen;
        PGameState.computer_seen = json.computer_seen;
        PGameState.telephone_seen = json.telephone_seen;
        PGameState.whiteboard_seen = json.whiteboard_seen;
        PGameState.hintscreen_seen = json.hintscreen_seen;
        PGameState.characterscreen_seen = json.characterscreen_seen;
        PGameState.aca_seen = json.aca_seen;
        PGameState.fi_seen = json.fi_seen;
        PGameState.as_seen = json.as_seen;
        PGameState.library_seen = json.library_seen;
        PGameState.evidence_seen = json.evidence_seen;
        PGameState.courtorder_seen = json.courtorder_seen;
        PGameState.seizedAssets = json.seizedAssets;

        return true;
    }

    static newData(i:number): void {
        PGameState.level = i;
        switch (i){
            case 1:
                PGameState.newCardDatabase([8,14],cards_1);
                PGameState.newTargetDatabase(targets_1);
                break;
            case 2:
                PGameState.newCardDatabase([8,14,20],cards_2);
                PGameState.newTargetDatabase(targets_2);
                break;
            case 3:
                PGameState.newCardDatabase([14,27,157,158,159,160,161,162,163,164,171,172,173,174,175,176,177,178,179,180,186,187,206,208,229,230,244,261],cards_3);
                PGameState.newTargetDatabase(targets_3);
                break;
            default:
                break;
        }

        PGameState.officeview_seen = false;
        PGameState.filefolder_seen = false;
        PGameState.computer_seen = false;
        PGameState.telephone_seen = false;
        PGameState.whiteboard_seen = false;
        PGameState.hintscreen_seen = false;
        PGameState.characterscreen_seen = false;
        PGameState.aca_seen = false;
        PGameState.fi_seen = false;
        PGameState.as_seen = false;
        PGameState.library_seen = false;
        PGameState.evidence_seen = false;
        PGameState.courtorder_seen = false;
        PGameState.seizedAssets = [];
    }

    static showDemo(step:number) : boolean {
        return false;
    }

    static newCardDatabase(unlockedCards,cards): void {
        this.allCards = [];
        this.unlockedCards = unlockedCards;
        for(let i=0;i<(<any>cards).default.length;i++){
            let card = new PCardModel();
            let cardJsonInfo = (<any>cards).default[i];
            card.description = cardJsonInfo.Description;
            card.id = Number(cardJsonInfo.ID);
            card.level = Number(cardJsonInfo.Level);
            card.subtype = cardJsonInfo.Subtype;
            if (Number(cardJsonInfo.Target1) != -1){
                card.target.push(Number(cardJsonInfo.Target1));
            }
            if (Number(cardJsonInfo.Target2) != -1){
                card.target.push(Number(cardJsonInfo.Target2));
            }
            if (Number(cardJsonInfo.NewSuspect) != -1){
                card.unlockSuspects.push(Number(cardJsonInfo.NewSuspect));
            }
            card.unlockWhiteboard.push(cardJsonInfo.Pic);
            card.type = Number(cardJsonInfo.Type);
            this.allCards.push(card);
        }

        for (let i=0;i<this.unlockedCards.length;i++){
            PGameState.allCards[this.unlockedCards[i]].target.forEach(target => {
                if (!this.unlockedTargets.includes(target)){
                    this.unlockedTargets.push(target);
                }
            });
        }
    }

    static newTargetDatabase(targets): void {
        this.allTargets = [];
        for(let i=0;i<(<any>targets).default.length;i++){
            let target = new PTargetModel();
            let targetJsonInfo = (<any>targets).default[i];

            target.id = Number(targetJsonInfo.ID);
            target.name = targetJsonInfo.Name;
            target.resource = targetJsonInfo.Resource;
            target.type = Number(targetJsonInfo.Type);
            target.aca1 = targetJsonInfo.ACA1;
            target.aca2 = targetJsonInfo.ACA2;
            target.fi = targetJsonInfo.FI;
            target.pi = targetJsonInfo.PI;
            target.difficulty = targetJsonInfo.Difficulty;

            this.allTargets.push(target);
        }
    }

    static getCount(type: number, level: number): number{
        let count = 0;
        for (let i=0; i<PGameState.unlockedCards.length; i++){
            if (PGameState.allCards[PGameState.unlockedCards[i]].type == type && PGameState.allCards[PGameState.unlockedCards[i]].level == level){
                count++;
            } 
        }
        return count;
    }

    static unlockAll(): void {
        this.unlockedCards = [];
        this.unlockedTargets = [];

        for (let i=0;i<this.allCards.length;i++){
            this.unlockedCards.push(this.allCards[i].id);
        }
        for (let i=0;i<this.allTargets.length;i++){
            this.unlockedTargets.push(this.allTargets[i].id);
        }
    }

    static newCardsLinkChecker(target: number, cards: number[]){
        let linkTargets = [];

        for (let i = 0; i<cards.length; i++){
            let links = [];

            // Check if target is included in a link
            PGameState.allCards[cards[i]].target.forEach(target => {
                linkTargets.forEach((link, index) => {
                    if (link.includes(target)) {
                        links.push(index);
                    }
                });
            });

            // If no link is found, create a new one
            if (links.length <= 0){
                var newLink = [];
                PGameState.allCards[cards[i]].target.forEach(target => {
                    newLink.push(target);
                });
                linkTargets.push(newLink);
            }
            
            // If only 1 target points to a link, extend it
            else if (links.length == 1){
                PGameState.allCards[cards[i]].target.forEach(myTarget => {
                    if (!linkTargets[links[0]].includes(myTarget)){
                        linkTargets[links[0]].push(myTarget);
                    } 
                });
            }
            
            // If both targets point to different links, merge them
            else if (links.length == 2 && (links[0] != links[1])){
                linkTargets[links[1]].forEach(target => {
                    if (!linkTargets[links[0]].includes(target)){
                        linkTargets[links[0]].push(target);
                    } 
                });
                linkTargets.splice(links[1],1);
            }
        }

        // Determine link combination of actual target
        var linkIndex = -1;
        linkTargets.forEach((link, index) => {
            if (link.includes(target)) {
                linkIndex = index;
            }
        });

        if (linkIndex == -1){
            return [];
        } else {
            return linkTargets[linkIndex];
        }
    }

    static checkNewCards(target: number, type:number, level: number, cards: number[], dialog: MatDialog): void {
        let usedCards = [];
        console.log(type);
        if (level == 0 && type != 3){
            if (PGameState.allTargets[target].pi <= 0){
                dialog.open(DeadEndDialogMessageComponent);
                return;
            } else {
                let newCards = PGameState.getNewCards(target,0,level);
                let successMessage = dialog.open(SuccessDialogMessageComponent);
                if (newCards.length <= 0){
                    successMessage.componentInstance.message = "Your Investigation request was successful! Unfortunately the investigation didn't lead to any new evidence.";
                } else {
                    successMessage.componentInstance.message = "Your Investigation request was successful! New evidence has been added to your file folder!"; 
                }
            }
            return;
        }
        if (type == 3){
            if (PGameState.seizedAssets.includes(target)){
                let errorMessage = dialog.open(ErrorDialogMessageComponent);
                errorMessage.componentInstance.message = "This asset was already seized. You cannot seize the same asset multiple times!";
            } else {
                var linkedTargets = PGameState.newCardsLinkChecker(target,cards);
                // Add Cards based on their target involvement
                for (let i = 0; i<cards.length; i++){
                    var validTarget = false;
                    PGameState.allCards[cards[i]].target.forEach(cardTarget => {
                        if (linkedTargets.includes(cardTarget)){
                            validTarget = true;
                        }
                    });
                    if (!usedCards.includes(cards[i]) && validTarget && PGameState.allCards[cards[i]].level >= 1){
                        usedCards.push(cards[i]);
                    }
                }

                if (usedCards.length <= 0){
                    let errorMessage = dialog.open(ErrorDialogMessageComponent);
                    errorMessage.componentInstance.message = "Your request was denied! Please make sure to add information or evidence involving your requested asset!";
                } else {
                    let successMessage = dialog.open(SuccessDialogMessageComponent);
                    successMessage.componentInstance.message = "Your asset request was successful! You'll receive additional information via E-Mail!";
                    PGameState.seizedAssets.push(target);
                }


            }
        }
        if (type == 1){
            if (PGameState.allTargets[target].fi <= 0){
                dialog.open(DeadEndDialogMessageComponent);
                return;
            } else {
                var linkedTargets = PGameState.newCardsLinkChecker(target,cards);
                // Add Cards based on their target involvement
                for (let i = 0; i<cards.length; i++){
                    var validTarget = false;
                    PGameState.allCards[cards[i]].target.forEach(cardTarget => {
                        if (linkedTargets.includes(cardTarget)){
                            validTarget = true;
                        }
                    });
                    if (!usedCards.includes(cards[i]) && validTarget && PGameState.allCards[cards[i]].level >= 1){
                        usedCards.push(cards[i]);
                    }
                }
                if (usedCards.length < PGameState.allTargets[target].difficulty){
                    let errorMessage = dialog.open(ErrorDialogMessageComponent);
                    errorMessage.componentInstance.message = "Your Request for a Financial Investigation was not successful. Provide more evidence involving your investigation target with an investigation level of 1 or higher";
                } else {
                    let newCards = PGameState.getNewCards(target,type,level);
                    let successMessage = dialog.open(SuccessDialogMessageComponent);
                    if (newCards.length <= 0){
                        successMessage.componentInstance.message = "Your Investigation request was successful! Unfortunately the investigation didn't lead to any new evidence.";
                    } else {
                        successMessage.componentInstance.message = "Your Investigation request was successful! New evidence has been added to your file folder!"; 
                    }
                }
            }
        } else if (type == 2 && level == 1){
            if (PGameState.allTargets[target].aca1 <= 0){
                dialog.open(DeadEndDialogMessageComponent);
                return;
            } else {
                var linkedTargets = PGameState.newCardsLinkChecker(target,cards);
                // Add Cards based on their target involvement
                for (let i = 0; i<cards.length; i++){
                    var validTarget = false;
                    PGameState.allCards[cards[i]].target.forEach(cardTarget => {
                        if (linkedTargets.includes(cardTarget)){
                            validTarget = true;
                        }
                    });
                    if (!usedCards.includes(cards[i]) && validTarget && PGameState.allCards[cards[i]].level <= 1){
                        usedCards.push(cards[i]);
                    }
                }
                if (usedCards.length < PGameState.allTargets[target].difficulty){
                    let errorMessage = dialog.open(ErrorDialogMessageComponent);
                    errorMessage.componentInstance.message = "Your Request for an ACA Investigation was not successful. Provide more evidence involving your investigation target with an investigation level of 1 or lower";
                } else {                    
                    let newCards = PGameState.getNewCards(target,type,level);
                    let successMessage = dialog.open(SuccessDialogMessageComponent);
                    if (newCards.length <= 0){
                        successMessage.componentInstance.message = "Your Investigation request was successful! Unfortunately the investigation didn't lead to any new evidence.";
                    } else {
                        successMessage.componentInstance.message = "Your Investigation request was successful! New evidence has been added to your file folder!"; 
                    }
                }
            }
        } else if (type == 2 && level == 2){
            if (PGameState.allTargets[target].aca2 <= 0){
                dialog.open(DeadEndDialogMessageComponent);
                return;
            } else {
                var linkedTargets = PGameState.newCardsLinkChecker(target,cards);
                // Add Cards based on their target involvement
                for (let i = 0; i<cards.length; i++){
                    var validTarget = false;
                    PGameState.allCards[cards[i]].target.forEach(cardTarget => {
                        if (linkedTargets.includes(cardTarget)){
                            validTarget = true;
                        }
                    });
                    if (!usedCards.includes(cards[i]) && validTarget && PGameState.allCards[cards[i]].level >= 1){
                        usedCards.push(cards[i]);
                    }
                }
                if (usedCards.length < PGameState.allTargets[target].difficulty){
                    let errorMessage = dialog.open(ErrorDialogMessageComponent);
                    errorMessage.componentInstance.message = "Your Request for an ACA Investigation was not successful. Provide more evidence involving your investigation target  with an investigation level of 1 or higher";
                } else {
                    let newCards = PGameState.getNewCards(target,type,level);
                    let successMessage = dialog.open(SuccessDialogMessageComponent);
                    if (newCards.length <= 0){
                        successMessage.componentInstance.message = "Your Investigation request was successful! Unfortunately the investigation didn't lead to any new evidence.";
                    } else {
                        successMessage.componentInstance.message = "Your Investigation request was successful! New evidence has been added to your file folder!"; 
                    }
                }
            }
        }
    }

    static getNewCards(target: number, type:number, level: number): number[]{
        let newCards = [];
        for (let i=0; i<this.allCards.length;i++){
            if (this.allCards[i].target.includes(target) && this.allCards[i].level == level && this.allCards[i].type == type && !this.unlockedCards.includes(this.allCards[i].id)){
                newCards.push(this.allCards[i]);
                this.unlockedCards.push(this.allCards[i].id);
            }
        }

        for (let n=0; n<newCards.length;n++){ 
        newCards[n].target.forEach(target => {
                if (!this.unlockedTargets.includes(target)){
                    this.unlockedTargets.push(target);
                }
            });
        }
        return newCards;
    }
}