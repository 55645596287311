import { IScene } from '../interfaces/i-scene';
import { HScene } from '../helpers/h-scene';
import { PButton } from '../pixielements/pbutton';
import { PSideNavigation } from '../pixielements/psidenavigation';

import * as PIXI from 'pixi.js-legacy';
import { HSceneManager } from '../helpers/h-scenemanager';
import { EScene } from '../enums/e-scene';
import { PGameState } from '../piximodels/pgamestate';

export class OfficeScreen extends HScene implements IScene {
    UpdateScene(): void {}
    ASSET_FOLDER = this.ASSET_FOLDER + 'OfficeView';
    PREFIX = 'OfficeScreen_';    
    MENU = new PSideNavigation(2,this);

    BuildScene(): PIXI.Container {
        const container = new PIXI.Container();

        // Background
        const background = this.LoadSprite('background',0,0);
        const foreground = this.LoadSprite('foreground',0,0);
        const plant1 = this.LoadSprite('plant1',1270,225);
        const plant2 = this.LoadSprite('plant2',1430,35);
        const label_computer = this.LoadSprite('label_computer',1480,481,false);
        const label_filecabinet = this.LoadSprite('label_filecabinet',1200,270,false);
        const label_telephone = this.LoadSprite('label_telephone',459,680,false);
        const label_whiteboard = this.LoadSprite('label_whiteboard',1042,440,false);

        // computer Button
        const computer = new PButton(
            this.LoadTexture('computer'),
            this.LoadTexture('computer_hover'),
            this.LoadTexture('computer_click'),
            1606, 515,
            label_computer
        );
        computer.on('click', (event) => {
            this.GoToComputer();
            if (PGameState.level == 1 && !PGameState.computer_seen){
                HSceneManager.INSTANCE.showMessage("<b>You discovered your computer!</b><br><br>As you have already discovered in the beginning of the game, your computer gives you access to your <b>E-Mails</b>. Those might contain additional informations on cases and function as an inbox of successfully seized assets.<br>In addition to your E-Mails, you may also access the <b>Web</b> from your computer. You can access unique news articles from there, which are documenting your current progress. If you are not sure how far you have progressed, you might want to have a quick peak to get an understanding of your current progression.<br><br>If you have any kind of <b>Feedback</b> for this game, you can find a dedicated icon on the computer to open your actual mail program of choice!");
                PGameState.computer_seen = true;
            }
        });

        // filecabinet Button
        const filecabinet = new PButton(
            this.LoadTexture('filecabinet'),
            this.LoadTexture('filecabinet_hover'),
            this.LoadTexture('filecabinet_click'),
            1500, 0,
            label_filecabinet
        );
        filecabinet.on('click', (event) => {
            this.GoToFiles();
            if (PGameState.level == 1 && !PGameState.filefolder_seen){
                HSceneManager.INSTANCE.showMessage("<b>This is your file folder!</b><br><br>Here you can view your available evidence in the <b>Information</b> section. Depending on your current role, you can also start different kind of investigations and start asset seizures from here by filling out the required forms for them. Start by trying a simple <b>ACA Investigation</b> on a target of your choice!<br><br>If you need any general advice on money laundering, you can check out the <b>Library</b> to view some literature regarding that topic.");
                PGameState.filefolder_seen = true;
            }
        });

        // telephone Button
        const telephone = new PButton(
            this.LoadTexture('telephone'),
            this.LoadTexture('telephone_hover'),
            this.LoadTexture('telephone_click'),
            630, 755,
            label_telephone
        );
        telephone.on('click', (event) => {
            this.GoToTelephone();
            if (PGameState.level == 1 && !PGameState.telephone_seen){
                HSceneManager.INSTANCE.showMessage("<b>This is your Telephone!</b><br><br>During your investigations, you might unlock phone numbers of certain individuals, which are shown and accessible in your <b>Address Book</b>. Although talking to Suspects will not give you any new usable evidence, it may reveal new suspects to potentially investigate.");
                PGameState.telephone_seen = true;
            }
        });

        // whiteboard Button
        const whiteboard = new PButton(
            this.LoadTexture('whiteboard'),
            this.LoadTexture('whiteboard_hover'),
            this.LoadTexture('whiteboard_click'),
            915, 116,
            label_whiteboard
        );
        whiteboard.on('click', (event) => {
            this.GoToWhiteboard();
            if (PGameState.level == 1 && !PGameState.whiteboard_seen){
                HSceneManager.INSTANCE.showMessage("<b>Say Hello to your Whiteboard!</b><br><br>On this whiteboard, you can see all key relations proven by your available evidence. Whenever you discover new evidence, additional connections are automatically added to showcase the current relations of your investigations.");
                PGameState.whiteboard_seen = true;
            }
        });

        // Add Elements to Canvas
        container.addChild(background);
        container.addChild(foreground);
        container.addChild(filecabinet);
        container.addChild(computer);
        container.addChild(telephone);
        container.addChild(whiteboard);
        container.addChild(plant1);
        container.addChild(plant2);
        container.addChild(label_computer);
        container.addChild(label_filecabinet);
        container.addChild(label_telephone);
        container.addChild(label_whiteboard);
        container.addChild(this.MENU.BuildScene());
        return container;
    }

    LoadResources(): void {
        this.AddResource('background', 'Background.png');
        this.AddResource('foreground', 'Foreground.png');
        this.AddResource('computer', 'Computer_Neutral.png');
        this.AddResource('computer_hover', 'Computer_Outline_Transparent.png');
        this.AddResource('computer_click', 'Computer_Outline.png');
        this.AddResource('filecabinet', 'FileCabinet_Neutral.png');
        this.AddResource('filecabinet_hover', 'FileCabinet_Outline_Transparent.png');
        this.AddResource('filecabinet_click', 'FileCabinet_Outline.png');
        this.AddResource('telephone', 'Telephone_Neutral.png');
        this.AddResource('telephone_hover', 'Telephone_Outline_Transparent.png');
        this.AddResource('telephone_click', 'Telephone_Outline.png');
        this.AddResource('whiteboard', 'Whiteboard_Neutral.png');
        this.AddResource('whiteboard_hover', 'Whiteboard_Outline_Transparant.png');
        this.AddResource('whiteboard_click', 'Whiteboard_Outline.png');
        this.AddResource('plant1', 'PlantGround.png');
        this.AddResource('plant2', 'HangingPlant.png');
        this.AddResource('label_computer', 'Label_Computer.png');
        this.AddResource('label_filecabinet', 'Label_FileFolder.png');
        this.AddResource('label_telephone', 'Label_Telepfone.png');
        this.AddResource('label_whiteboard', 'Label_Whiteboard.png');
        this.MENU.LoadResources();
    }

    GoToComputer(){
        HSceneManager.INSTANCE.changeSceneById(EScene.COMPUTERSCREEN); 
    }

    GoToFiles(){
        HSceneManager.INSTANCE.changeSceneById(EScene.FILEFOLDERSCREEN);
    }

    GoToTelephone(){
        HSceneManager.INSTANCE.changeSceneById(EScene.TELEPHONESCREEN);
    }

    GoToWhiteboard(){
        HSceneManager.INSTANCE.changeSceneById(EScene.WHITEBOARDSCREEN);
    }
}
