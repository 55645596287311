import { MatDialog } from '@angular/material/dialog';
import * as PIXI from 'pixi.js-legacy';
import { HScene } from '../helpers/h-scene';
import { IScene } from '../interfaces/i-scene';
import { PGameState } from '../piximodels/pgamestate';
import { PButton } from './pbutton';
import { PCard } from './pcard';
import { FilterSelectionTypeComponent } from './../filter-selection-type/filter-selection-type.component';
import { FilterSelectionLevelComponent } from './../filter-selection-level/filter-selection-level.component';
import { FilterSelectionSuspectComponent } from './../filter-selection-suspect/filter-selection-suspect.component';
import { HSceneManager } from '../helpers/h-scenemanager';

export class PInformationScreen extends HScene implements IScene {
    static loaded = false;
    ASSET_FOLDER = './../../assets/InformationScreenPNGs';
    PREFIX = 'InformationScreen';

    CALLBACK: (id) => void = null;
    CLOSE: () => void = null;

    CARDS = [];
    page = 0;
    pagelimit = 3;

    pageText = null;
    prevButton = null;
    nextButton = null;

    filter_type = -1;
    filter_level = -1;
    filter_target = -1;

    dialog: MatDialog;
    filter_type_selection = null;
    filter_level_selection = null;
    filter_target_selection = null;

    viewReference = null;
    filteredCards = [];

    constructor(dialog: MatDialog){
        super();
        this.dialog = dialog;
    }

    ResetData(){
        this.page = 0;

        this.filter_type = -1;
        this.filter_level = -1;
        this.filter_target = -1;
    }

    ConnectFallback(connectFeedback: (id) => void,closeEvent: () => void): void {
        this.CALLBACK = connectFeedback;
        this.CLOSE = closeEvent;
    }

    FilterCards(): void {
        this.filteredCards = [];
        for (let i=PGameState.unlockedCards.length-1; i>=0; i--){
            let card = PGameState.allCards[PGameState.unlockedCards[i]];

            if (card.type != this.filter_type && this.filter_type != -1){
                continue;
            }
            if (card.level != this.filter_level && this.filter_level != -1){
                continue;
            }
            if (card.target[0] != this.filter_target && card.target[1] != this.filter_target && this.filter_target != -1){
                continue;
            }
            this.filteredCards.push(PGameState.unlockedCards[i]);
        }
    }
    
    UpdateScene(): void {
        for (let i=0; i<this.CARDS.length; i++){
            this.viewReference.removeChild(this.CARDS[i]);
        }

        this.CARDS = [];
        this.FilterCards();

        for (let i=0; i<this.filteredCards.length - (this.page*this.pagelimit) && i<this.pagelimit; i++){
            const cardData = PGameState.allCards[this.filteredCards[i + (this.page*this.pagelimit)]];
            this.CARDS.push(new PCard(cardData));
            this.CARDS[i].anchor.set(0.5,0.5);
            this.CARDS[i].position.set(630+((i)*350),650);
            this.CARDS[i].scale.set(0.65,0.65);
            this.CARDS[i].interactive = true;
            this.viewReference.addChild(this.CARDS[i]);
            if (this.CALLBACK != null){
                this.CARDS[i].on('click', (event) => {
                    this.CALLBACK(cardData.id);
                    this.CLOSE();
                    HSceneManager.INSTANCE.animate();
                });
            }
        }

        let pageMax = Math.ceil(this.filteredCards.length/this.pagelimit);
        if (pageMax <= 0){
            pageMax = 1;
        }
        this.pageText.text = "Page: " + (this.page+1) + " / " + pageMax;
        this.UpdatePager();
    }

    BuildScene(): PIXI.Container {
        this.ResetData();
        this.viewReference = new PIXI.Container();
        const background = this.LoadSprite('background',0,0);
        const bg_area = this.LoadSprite('background_area',980,550);
        bg_area.anchor.set(0.5,0.5);
        bg_area.scale.set(1.2,1);

        this.pageText = this.LoadText("", 1440, 900);
        this.pageText.style = this.GetPageStyle();

        const header = this.LoadSprite('header',980,215);
        header.anchor.set(0.5,0.5);

        const close_button = new PButton(
            this.LoadTexture('close'),
            this.LoadTexture('close'),
            this.LoadTexture('close'),
            1500, 165
        );
        close_button.on('click', (event) => {
            this.CLOSE();
            HSceneManager.INSTANCE.animate();
        });

        this.nextButton = new PButton(
            this.LoadTexture('right'),
            this.LoadTexture('right'),
            this.LoadTexture('right'),
            1515, 610
        );
        this.nextButton.on('click', (event) => {
            this.page++;
            this.UpdateScene();
            HSceneManager.INSTANCE.animate();
        });

        this.prevButton = new PButton(
            this.LoadTexture('left'),
            this.LoadTexture('left'),
            this.LoadTexture('left'),
            387, 610
        );
        this.prevButton.on('click', (event) => {
            this.page--;
            this.UpdateScene();
            HSceneManager.INSTANCE.animate();
        });

        const filter_type_label = this.LoadText("Type", 980, 310);
        filter_type_label.style = this.GetLabelStyle();
        filter_type_label.anchor.set(0.5,0.5);

        this.filter_type_selection = this.LoadText("All", 980, 365);
        this.filter_type_selection.style = this.GetPageStyle();
        this.filter_type_selection.anchor.set(0.5,0.5);

        const filter_type = new PButton(
            this.LoadTexture('filter'),
            this.LoadTexture('filter'),
            this.LoadTexture('filter'),
            980, 365
        );
        filter_type.anchor.set(0.5,0.5);
        filter_type.scale.set(1.25,1.25);
        filter_type.on('click', (event) => {
            let dialogRef = this.dialog.open(FilterSelectionTypeComponent);
            dialogRef.componentInstance.CALLBACK = (name,value) => {
                this.filter_type = value;
                this.filter_type_selection.text = name;
                this.page = 0;
                this.UpdateScene();
                HSceneManager.INSTANCE.animate();
            };
            HSceneManager.INSTANCE.animate();
        });

        const filter_target_label = this.LoadText("Suspect", 630, 310);
        filter_target_label.style = this.GetLabelStyle();
        filter_target_label.anchor.set(0.5,0.5);

        this.filter_target_selection = this.LoadText("All", 630, 365);
        this.filter_target_selection.style = this.GetPageStyle();
        this.filter_target_selection.anchor.set(0.5,0.5);

        const filter_target = new PButton(
            this.LoadTexture('filter'),
            this.LoadTexture('filter'),
            this.LoadTexture('filter'),
            630, 365
        );
        filter_target.anchor.set(0.5,0.5);
        filter_target.scale.set(1.25,1.25);
        filter_target.on('click', (event) => {
            let dialogRef = this.dialog.open(FilterSelectionSuspectComponent);
            dialogRef.componentInstance.CALLBACK = (name,value) => {
                this.filter_target = value;
                this.filter_target_selection.text = name;
                this.page = 0;
                this.UpdateScene();
                HSceneManager.INSTANCE.animate();
            };
            HSceneManager.INSTANCE.animate();
        });

        const filter_level_label = this.LoadText("Level", 1330, 310);
        filter_level_label.style = this.GetLabelStyle();
        filter_level_label.anchor.set(0.5,0.5);

        this.filter_level_selection = this.LoadText("All", 1330, 365);
        this.filter_level_selection.style = this.GetPageStyle();
        this.filter_level_selection.anchor.set(0.5,0.5);

        const filter_level = new PButton(
            this.LoadTexture('filter'),
            this.LoadTexture('filter'),
            this.LoadTexture('filter'),
            1330, 365
        );
        filter_level.anchor.set(0.5,0.5);
        filter_level.scale.set(1.25,1.25);
        filter_level.on('click', (event) => {
            let dialogRef = this.dialog.open(FilterSelectionLevelComponent);
            dialogRef.componentInstance.CALLBACK = (name,value) => {
                this.filter_level = value;
                this.filter_level_selection.text = name;
                this.page = 0;
                this.UpdateScene();
                HSceneManager.INSTANCE.animate();
            };
            HSceneManager.INSTANCE.animate();
        });

        this.viewReference.addChild(background);
        this.viewReference.addChild(bg_area);
        this.viewReference.addChild(header);
        this.viewReference.addChild(close_button);
        this.viewReference.addChild(this.nextButton);
        this.viewReference.addChild(this.prevButton);
        this.viewReference.addChild(this.pageText);
        this.viewReference.addChild(filter_type);
        this.viewReference.addChild(filter_target);
        this.viewReference.addChild(filter_level);
        this.viewReference.addChild(filter_type_label);
        this.viewReference.addChild(filter_target_label);
        this.viewReference.addChild(filter_level_label);
        this.viewReference.addChild(this.filter_target_selection);
        this.viewReference.addChild(this.filter_type_selection);
        this.viewReference.addChild(this.filter_level_selection);

        this.FilterCards();

        this.CARDS = [];
        for (let i=0; i<this.filteredCards.length && i<this.pagelimit; i++){
            this.CARDS.push(new PCard(PGameState.allCards[this.filteredCards[i]]));
            this.CARDS[i].anchor.set(0.5,0.5);
            this.CARDS[i].position.set(630+(i*350),650);
            this.CARDS[i].scale.set(0.65,0.65);
            this.CARDS[i].interactive = true;
            this.viewReference.addChild(this.CARDS[i]);
        }

        this.UpdateScene();

        return this.viewReference;
    }

    UpdatePager(){
        if (this.page <= 0){
            this.prevButton.visible = false;
        } else {
            this.prevButton.visible = true;
        }
        if ((this.page+1) < this.filteredCards.length/this.pagelimit){
            this.nextButton.visible = true;
        } else {
            this.nextButton.visible = false;
        }
    }

    LoadResources(): void {
        if (PInformationScreen.loaded){
            return;
        }
        this.AddResource('background', 'Background.png');
        this.AddResource('background_area', 'BG.png');
        this.AddResource('close', 'Evidences_CloseButton.png');
        this.AddResource('header', 'Evidences_Header.png');
        this.AddResource('filter', 'Evidences_ButtonBase.png');
        this.AddResource('left', 'Evidences_LeftButton.png');
        this.AddResource('right', 'Evidences_RightButton.png');

        PInformationScreen.loaded = true;
    }

    GetPageStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 24
        });
    }

    GetLabelStyle(): PIXI.TextStyle {
        return new PIXI.TextStyle({
            fontSize: 32,
            fontWeight: 'bold'
        });
    }
}
